import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  CosmosObjectIdString: any;
  Currency: any;
  DID: any;
  Date: any;
  DateString: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type A_Applicant = {
  __typename?: "A_Applicant";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type A_CaseDetails = {
  __typename?: "A_CaseDetails";
  applicationType?: Maybe<Scalars["String"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  isApplicationCreationAllowed?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  isRequiredDataProvided?: Maybe<Scalars["Boolean"]>;
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["String"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
};

export type A_Progress = {
  __typename?: "A_Progress";
  englishEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  generalEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  pathwayEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
};

export type A_ProgressStatus = {
  __typename?: "A_ProgressStatus";
  checklistRecord?: Maybe<Scalars["String"]>;
  checklistState?: Maybe<Scalars["String"]>;
};

export type ActivityLogType = {
  __typename?: "ActivityLogType";
  logType?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
  user?: Maybe<ActivityLogUserType>;
};

export type ActivityLogUserType = {
  __typename?: "ActivityLogUserType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type AddMiniCexInput = {
  physicianEmail: Scalars["String"];
  physicianLastNameProvidedByApplicant: Scalars["String"];
  physicianRestOfNameProvidedByApplicant: Scalars["String"];
};

export type AdditionalDocument = {
  __typename?: "AdditionalDocument";
  document?: Maybe<Document>;
  notes?: Maybe<Scalars["String"]>;
};

export type AdditionalDocumentInput = {
  document?: InputMaybe<DocumentInput>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type ApplicantLoginPayloadType = {
  __typename?: "ApplicantLoginPayloadType";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

export type ApplicantPortalLoginResponseType = {
  __typename?: "ApplicantPortalLoginResponseType";
  applicant?: Maybe<ApplicantLoginPayloadType>;
  case?: Maybe<CaseLoginPayloadType>;
  token?: Maybe<Scalars["String"]>;
};

export type ApplicantPortalMutationResponseType = {
  __typename?: "ApplicantPortalMutationResponseType";
  data?: Maybe<ApplicantPortalResponseType>;
  status?: Maybe<Scalars["String"]>;
};

export type ApplicantPortalResponseType = {
  __typename?: "ApplicantPortalResponseType";
  _id?: Maybe<Scalars["ID"]>;
  applicant?: Maybe<A_Applicant>;
  caseConfig?: Maybe<CaseConfigType>;
  caseDetails?: Maybe<A_CaseDetails>;
  eligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  finance?: Maybe<Finance>;
  graduation?: Maybe<GraduationType>;
  languageAssessment?: Maybe<LanguageAssessment>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  progress?: Maybe<A_Progress>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type ApplicantPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterApplicantInput;
};

export type ArchivedCaseApplicantGraduationType = {
  __typename?: "ArchivedCaseApplicantGraduationType";
  country?: Maybe<Scalars["String"]>;
  credComments?: Maybe<Scalars["String"]>;
  credStatusCode?: Maybe<Scalars["String"]>;
  degree?: Maybe<Scalars["String"]>;
  school?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseApplicantProfileType = {
  __typename?: "ArchivedCaseApplicantProfileType";
  dateOfBirth?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseApplicantType = {
  __typename?: "ArchivedCaseApplicantType";
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  graduation?: Maybe<ArchivedCaseApplicantGraduationType>;
  profile?: Maybe<ArchivedCaseApplicantProfileType>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
};

export type ArchivedCaseAssessmentEligibilityOutputType = {
  __typename?: "ArchivedCaseAssessmentEligibilityOutputType";
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseAssessmentEligibilityType = {
  __typename?: "ArchivedCaseAssessmentEligibilityType";
  eligibilityPreCheck?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
  generalEligibility?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
};

export type ArchivedCaseAssessmentMinicexType = {
  __typename?: "ArchivedCaseAssessmentMinicexType";
  assessment?: Maybe<MiniCexAssessmentType>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
};

export type ArchivedCaseAssessmentMinicexTypeMinicexListArgs = {
  filter: MiniCexListFilterCaseManagerInput;
};

export type ArchivedCaseAssessmentOetType = {
  __typename?: "ArchivedCaseAssessmentOETType";
  candidateId?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  examDate?: Maybe<Scalars["String"]>;
  examineeFirstName?: Maybe<Scalars["String"]>;
  examineeLastName?: Maybe<Scalars["String"]>;
  listeningScore?: Maybe<Scalars["Float"]>;
  readingScore?: Maybe<Scalars["Float"]>;
  result?: Maybe<Scalars["String"]>;
  speakingScore?: Maybe<Scalars["Float"]>;
  testingCenter?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
  writingScore?: Maybe<Scalars["Float"]>;
};

export type ArchivedCaseAssessmentsType = {
  __typename?: "ArchivedCaseAssessmentsType";
  eligibility?: Maybe<ArchivedCaseAssessmentEligibilityType>;
  emswp?: Maybe<EducationAttestation>;
  minicex?: Maybe<ArchivedCaseAssessmentMinicexType>;
  oet?: Maybe<ArchivedCaseAssessmentOetType>;
};

export type ArchivedCaseAuditType = {
  __typename?: "ArchivedCaseAuditType";
  archivedAt?: Maybe<Scalars["DateString"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseDecisionType = {
  __typename?: "ArchivedCaseDecisionType";
  executedBy: UserType;
  output: Scalars["String"];
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ArchivedCaseDetailsType = {
  __typename?: "ArchivedCaseDetailsType";
  applicationType?: Maybe<Scalars["String"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  decision?: Maybe<ArchivedCaseDecisionType>;
  isRefunded?: Maybe<Scalars["Boolean"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseFilterInput = {
  usmleId: Scalars["String"];
};

export type ArchivedCaseFinanceRevenueRecognitionType = {
  __typename?: "ArchivedCaseFinanceRevenueRecognitionType";
  amount?: Maybe<Scalars["Int"]>;
  creditGlAccount?: Maybe<Scalars["String"]>;
  debitGlAccount?: Maybe<Scalars["String"]>;
  pnref?: Maybe<Scalars["String"]>;
  revenueRecognizedAt?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseFinanceType = {
  __typename?: "ArchivedCaseFinanceType";
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
  revenueRecognition?: Maybe<
    Array<Maybe<ArchivedCaseFinanceRevenueRecognitionType>>
  >;
};

export type ArchivedCaseOetReviewType = {
  __typename?: "ArchivedCaseOetReviewType";
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<LanguageAssessmentData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway1ReviewType = {
  __typename?: "ArchivedCasePathway1ReviewType";
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<LicenseData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway2ReviewType = {
  __typename?: "ArchivedCasePathway2ReviewType";
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<CsExamData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway6ReviewType = {
  __typename?: "ArchivedCasePathway6ReviewType";
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway345ReviewType = {
  __typename?: "ArchivedCasePathway345ReviewType";
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<EducationData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathwayXReviewType = {
  __typename?: "ArchivedCasePathwayXReviewType";
  checklist?: Maybe<ExceptionRequestChecklist>;
  data?: Maybe<ExceptionRequestData>;
  exceptionChecklistState?: Maybe<Scalars["String"]>;
  pathwayChecklistState?: Maybe<Scalars["String"]>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCaseReviewSummaryType = {
  __typename?: "ArchivedCaseReviewSummaryType";
  _id: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseReviewsType = {
  __typename?: "ArchivedCaseReviewsType";
  oet?: Maybe<Array<Maybe<ArchivedCaseOetReviewType>>>;
  pathway1?: Maybe<Array<Maybe<ArchivedCasePathway1ReviewType>>>;
  pathway2?: Maybe<Array<Maybe<ArchivedCasePathway2ReviewType>>>;
  pathway6?: Maybe<Array<Maybe<ArchivedCasePathway6ReviewType>>>;
  pathway345?: Maybe<Array<Maybe<ArchivedCasePathway345ReviewType>>>;
  pathwayX?: Maybe<Array<Maybe<ArchivedCasePathwayXReviewType>>>;
};

export type ArchivedCaseSummaryType = {
  __typename?: "ArchivedCaseSummaryType";
  _id: Scalars["String"];
  applicationType?: Maybe<Scalars["String"]>;
  decisionOutput?: Maybe<Scalars["String"]>;
  pathwayNumber?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  reviewCompletedOn?: Maybe<Scalars["DateString"]>;
  submittedOn?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseType = {
  __typename?: "ArchivedCaseType";
  _id: Scalars["String"];
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<ArchivedCaseApplicantType>;
  assessments?: Maybe<ArchivedCaseAssessmentsType>;
  audit?: Maybe<ArchivedCaseAuditType>;
  caseDetails?: Maybe<ArchivedCaseDetailsType>;
  externalId?: Maybe<Scalars["String"]>;
  finance?: Maybe<ArchivedCaseFinanceType>;
  reviews?: Maybe<ArchivedCaseReviewsType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type AssessmentCategoryType = {
  __typename?: "AssessmentCategoryType";
  avgScore?: Maybe<Scalars["Float"]>;
  failedEncounters?: Maybe<Array<Maybe<FailedEncounterType>>>;
  isFailedByAvgScore?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AttestMiniCexInput = {
  hasApplicantEnrolledInClinicalRotation: Scalars["String"];
};

export type AttestationRecordFilterInput = {
  attestationOutput?: InputMaybe<Scalars["String"]>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type AttestationRecordType = {
  __typename?: "AttestationRecordType";
  attestationOutput?: Maybe<Scalars["String"]>;
  completeTimestamp?: Maybe<Scalars["DateString"]>;
  completedBy?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gradSchool?: Maybe<Scalars["String"]>;
  gradSchoolCountry?: Maybe<Scalars["String"]>;
  gradYear?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  rejectionReasonText?: Maybe<Scalars["String"]>;
  requestTimestamp?: Maybe<Scalars["DateString"]>;
  uiConfig?: Maybe<UiPortalConfigType>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type AuthorityPortalResponseType = {
  __typename?: "AuthorityPortalResponseType";
  attestationRecords?: Maybe<Array<Maybe<AttestationRecordType>>>;
};

export type AuthorityPortalResponseTypeAttestationRecordsArgs = {
  filter?: InputMaybe<AttestationRecordFilterInput>;
};

export type CsExam = {
  __typename?: "CSExam";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<CsExamData>;
  review?: Maybe<CsExamReview>;
};

export type CsExamChecklist = {
  __typename?: "CSExamChecklist";
  osceDocumentCompleteLegible?: Maybe<Scalars["String"]>;
  osceExaminationAcceptable?: Maybe<Scalars["String"]>;
  osceExaminationAfterJuly1?: Maybe<Scalars["String"]>;
  osceExaminationPassed?: Maybe<Scalars["String"]>;
  osceNameOK?: Maybe<Scalars["String"]>;
  osceTranslation?: Maybe<Scalars["String"]>;
  verificationAcceptableDateConfirmed?: Maybe<Scalars["String"]>;
  verificationCompleted?: Maybe<Scalars["String"]>;
  verificationPassedExamConfirmed?: Maybe<Scalars["String"]>;
  verificationReturned?: Maybe<Scalars["String"]>;
};

export type CsExamChecklistInput = {
  osceDocumentCompleteLegible: Scalars["String"];
  osceExaminationAcceptable: Scalars["String"];
  osceExaminationAfterJuly1: Scalars["String"];
  osceExaminationPassed: Scalars["String"];
  osceNameOK: Scalars["String"];
  osceTranslation: Scalars["String"];
  verificationAcceptableDateConfirmed: Scalars["String"];
  verificationCompleted: Scalars["String"];
  verificationPassedExamConfirmed: Scalars["String"];
  verificationReturned: Scalars["String"];
};

export type CsExamData = {
  __typename?: "CSExamData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars["String"]>;
  oscExamRef?: Maybe<OscexamResponseType>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type CsExamDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars["String"]>;
  oscExamRef?: InputMaybe<OscexamInput>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type CsExamInput = {
  data?: InputMaybe<CsExamDataInput>;
};

export type CsExamReview = {
  __typename?: "CSExamReview";
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type CsExamReviewInput = {
  checklist?: InputMaybe<CsExamChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type C_ApplicantType = {
  __typename?: "C_ApplicantType";
  country?: Maybe<Scalars["String"]>;
  credComments?: Maybe<Scalars["String"]>;
  credStatusCode?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  degree?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  gradSchool?: Maybe<Scalars["String"]>;
  gradSchoolId?: Maybe<Scalars["Int"]>;
  gradYear?: Maybe<Scalars["String"]>;
  graduation?: Maybe<GraduationType>;
  isStep2CSFailed?: Maybe<Scalars["Boolean"]>;
  lastDataRefreshTimestamp?: Maybe<Scalars["DateString"]>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
};

/**  Required to enable Apollo Cache Control  */
export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CaseConfigRecordType = {
  __typename?: "CaseConfigRecordType";
  minEligibleGradDate?: Maybe<Scalars["Date"]>;
  minEligibleOETTestDate?: Maybe<Scalars["Date"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
};

export type CaseConfigType = {
  __typename?: "CaseConfigType";
  caseConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
  config?: Maybe<CaseConfigRecordType>;
};

export type CaseCountType = {
  __typename?: "CaseCountType";
  applicationCompleted?: Maybe<Scalars["Int"]>;
  applicationCreation?: Maybe<Scalars["Int"]>;
  applicationSubmitted?: Maybe<Scalars["Int"]>;
  assignedToUser?: Maybe<Scalars["Int"]>;
  eligibilityReview?: Maybe<Scalars["Int"]>;
  qaReady?: Maybe<Scalars["Int"]>;
  qaReview?: Maybe<Scalars["Int"]>;
};

export type CaseFilterInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  assignedToUser?: InputMaybe<Scalars["Boolean"]>;
  candidateId?: InputMaybe<Scalars["String"]>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  currentPathway?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  firstName?: InputMaybe<Scalars["String"]>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lastName?: InputMaybe<Scalars["String"]>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type CaseListPaginationInput = {
  pageNumber: Scalars["Int"];
  pageSize: Scalars["Int"];
  sortField: Scalars["String"];
  sortOrder: Scalars["String"];
};

export type CaseListPaginationType = {
  __typename?: "CaseListPaginationType";
  pageNumber: Scalars["Int"];
  pageSize: Scalars["Int"];
  sortField: Scalars["String"];
  sortOrder: Scalars["String"];
  totalPages: Scalars["Int"];
  totalRecords: Scalars["Int"];
};

export type CaseListResponseType = {
  __typename?: "CaseListResponseType";
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  pagination: CaseListPaginationType;
};

export type CaseLoginPayloadType = {
  __typename?: "CaseLoginPayloadType";
  applicationType?: Maybe<Scalars["String"]>;
  isApplicationCreationAllowed?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  state?: Maybe<Scalars["String"]>;
};

export type CaseNoteInput = {
  text: Scalars["String"];
};

export type CasePortalResponseType = {
  __typename?: "CasePortalResponseType";
  case?: Maybe<CaseResponseType>;
  caseCount?: Maybe<CaseCountType>;
  caseList?: Maybe<CaseListResponseType>;
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  getOETRecordCount?: Maybe<Scalars["Int"]>;
  getOETUnMatchedCount?: Maybe<Scalars["Int"]>;
  glDistributionConfig?: Maybe<Array<Maybe<GlDistributionConfig>>>;
  glDistributionReport?: Maybe<Array<Maybe<GlDistributionReportResponseType>>>;
  minicexByPhysician?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  oetPortal?: Maybe<OetPortalResponseType>;
  physician?: Maybe<PhysicianResponseType>;
  physicianByMiniCEX?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  physicians?: Maybe<Array<Maybe<PhysicianResponseType>>>;
};

export type CasePortalResponseTypeCaseArgs = {
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type CasePortalResponseTypeCaseListArgs = {
  filter?: InputMaybe<CaseFilterInput>;
  pagination: CaseListPaginationInput;
};

export type CasePortalResponseTypeCasesArgs = {
  filter?: InputMaybe<CaseFilterInput>;
};

export type CasePortalResponseTypeGlDistributionConfigArgs = {
  _id?: InputMaybe<Scalars["ID"]>;
};

export type CasePortalResponseTypeGlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

export type CasePortalResponseTypeMinicexByPhysicianArgs = {
  filter: MiniCexByPhysicianFilterCaseManagerInput;
};

export type CasePortalResponseTypePhysicianArgs = {
  email: Scalars["String"];
};

export type CasePortalResponseTypePhysicianByMiniCexArgs = {
  filter: PhysicianByMiniCexFilterCaseManagerInput;
};

export type CaseResponseStatusType = {
  __typename?: "CaseResponseStatusType";
  response?: Maybe<ResponseStatusType>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type CaseResponseType = {
  __typename?: "CaseResponseType";
  _id?: Maybe<Scalars["String"]>;
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<C_ApplicantType>;
  caseConfig?: Maybe<CaseConfigType>;
  caseSummary?: Maybe<CaseSummaryType>;
  finance?: Maybe<Finance>;
  languageAssessment?: Maybe<LanguageAssessment>;
  oetScore?: Maybe<OetResponseType>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type CaseSummaryType = {
  __typename?: "CaseSummaryType";
  _id?: Maybe<Scalars["String"]>;
  applicationType?: Maybe<Scalars["String"]>;
  caseState?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  eligibilityReviewAssigneeId?: Maybe<Scalars["String"]>;
  eligibilityReviewAssigneeName?: Maybe<Scalars["String"]>;
  englishEligibilityOutput?: Maybe<Scalars["String"]>;
  englishEligibilityStatus?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  generalEligibilityOutput?: Maybe<Scalars["String"]>;
  generalEligibilityOutputReason?: Maybe<Scalars["String"]>;
  generalEligibilityStatus?: Maybe<Scalars["String"]>;
  isEligible?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  isPathwayChanged?: Maybe<Scalars["Boolean"]>;
  lastDocUploadTimestamp?: Maybe<Scalars["DateString"]>;
  lastName?: Maybe<Scalars["String"]>;
  oetCandidateId?: Maybe<Scalars["String"]>;
  oetExamDate?: Maybe<Scalars["String"]>;
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwayEligibilityOutput?: Maybe<Scalars["String"]>;
  pathwayEligibilityStatus?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  qaReviewAssigneeId?: Maybe<Scalars["String"]>;
  qaReviewAssigneeName?: Maybe<Scalars["String"]>;
  qaReworkTimestamp?: Maybe<Scalars["DateString"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type CategoryAdditionalInfoType = {
  __typename?: "CategoryAdditionalInfoType";
  maxScore?: Maybe<Scalars["Int"]>;
  minScore?: Maybe<Scalars["Int"]>;
};

export type CategoryQualificationType = {
  __typename?: "CategoryQualificationType";
  minAvgScoreAcrossAllRequests?: Maybe<Scalars["Int"]>;
  minScore?: Maybe<Scalars["Int"]>;
};

export type Dashboard = {
  __typename?: "Dashboard";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  dashboardType?: Maybe<Scalars["String"]>;
  lastAccessedCaseView?: Maybe<LastAccessedView>;
  lastAccessedOETView?: Maybe<LastAccessedView>;
  oetViews?: Maybe<Array<Maybe<OetViews>>>;
  schemaVersion?: Maybe<Scalars["String"]>;
  views?: Maybe<Array<Maybe<Views>>>;
};

export type DashboardInput = {
  dashboardType?: InputMaybe<Scalars["String"]>;
  oetViews?: InputMaybe<Array<InputMaybe<OetViewsInput>>>;
  schemaVersion?: InputMaybe<Scalars["String"]>;
  views?: InputMaybe<Array<InputMaybe<ViewsInput>>>;
};

export type DeleteDashboardResponse = {
  __typename?: "DeleteDashboardResponse";
  success?: Maybe<Scalars["String"]>;
};

export type Document = {
  __typename?: "Document";
  docId?: Maybe<Scalars["String"]>;
  docType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  uploadedBy?: Maybe<DocumentUploadUser>;
};

export type DocumentInput = {
  docId?: InputMaybe<Scalars["String"]>;
  docType: Scalars["String"];
  title: Scalars["String"];
  uploadedBy?: InputMaybe<DocumentUploadUserInput>;
};

export type DocumentUploadUser = {
  __typename?: "DocumentUploadUser";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type DocumentUploadUserInput = {
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Education = {
  __typename?: "Education";
  _id?: Maybe<Scalars["ID"]>;
  attestation?: Maybe<EducationAttestation>;
  data?: Maybe<EducationData>;
  review?: Maybe<EducationReview>;
};

export type EducationAttestation = {
  __typename?: "EducationAttestation";
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Scalars["String"]>;
  outputReasonText?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type EducationChecklist = {
  __typename?: "EducationChecklist";
  attestationAuthorizedOfficial?: Maybe<Scalars["String"]>;
  attestationComplete?: Maybe<Scalars["String"]>;
  attestationCompletedByMedicalSchool?: Maybe<Scalars["String"]>;
  attestationSealOk?: Maybe<Scalars["String"]>;
  attestationSentPrimarySource?: Maybe<Scalars["String"]>;
  attestationSignatureDate?: Maybe<Scalars["String"]>;
  attestationSignatureMatch?: Maybe<Scalars["String"]>;
  attestationTitleOk?: Maybe<Scalars["String"]>;
  eligibilityGradDateEligible?: Maybe<Scalars["String"]>;
  eligibilityMedicalSchool?: Maybe<Scalars["String"]>;
};

export type EducationChecklistInput = {
  attestationAuthorizedOfficial: Scalars["String"];
  attestationComplete: Scalars["String"];
  attestationCompletedByMedicalSchool: Scalars["String"];
  attestationSealOk: Scalars["String"];
  attestationSentPrimarySource: Scalars["String"];
  attestationSignatureDate: Scalars["String"];
  attestationSignatureMatch: Scalars["String"];
  attestationTitleOk: Scalars["String"];
  eligibilityGradDateEligible: Scalars["String"];
  eligibilityMedicalSchool: Scalars["String"];
};

export type EducationData = {
  __typename?: "EducationData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  gradDate?: Maybe<Scalars["String"]>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type EducationDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  gradDate?: InputMaybe<Scalars["String"]>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type EducationInput = {
  data?: InputMaybe<EducationDataInput>;
};

export type EducationReview = {
  __typename?: "EducationReview";
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type EducationReviewInput = {
  checklist?: InputMaybe<EducationChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type Eligibility = {
  __typename?: "Eligibility";
  eligibilityCheckTimestamp?: Maybe<Scalars["DateString"]>;
  isEligible?: Maybe<Scalars["Boolean"]>;
  responseCode?: Maybe<Scalars["String"]>;
  responseMsg?: Maybe<Scalars["String"]>;
};

export type EligibilityPreCheckResponseType = {
  __typename?: "EligibilityPreCheckResponseType";
  eligibility?: Maybe<Eligibility>;
  isStep2CSFailed?: Maybe<Scalars["Boolean"]>;
  lastScreenName?: Maybe<Scalars["String"]>;
};

export type EventRepublishResponseType = {
  __typename?: "EventRepublishResponseType";
  id?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ExamHistoryType = {
  __typename?: "ExamHistoryType";
  examDate?: Maybe<Scalars["String"]>;
  examName?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ExceptionRequest = {
  __typename?: "ExceptionRequest";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<ExceptionRequestData>;
  review?: Maybe<ExceptionRequestReview>;
};

export type ExceptionRequestChecklist = {
  __typename?: "ExceptionRequestChecklist";
  applicantEligiblePathway1?: Maybe<Scalars["String"]>;
  applicantEligiblePathway2?: Maybe<Scalars["String"]>;
  applicantEligiblePathway345?: Maybe<Scalars["String"]>;
  applicantHasNotSubmittedAppForCert?: Maybe<Scalars["String"]>;
  applicantHasPurchasedToken2021Match?: Maybe<Scalars["String"]>;
  applicantIsNotCertified?: Maybe<Scalars["String"]>;
  applicantNotBarredByUSMLE?: Maybe<Scalars["String"]>;
  applicantNotValidStep2CSScore?: Maybe<Scalars["String"]>;
  ecfmgRestrictionsDoNotPreventAppExamination?: Maybe<Scalars["String"]>;
  exceptionApproved?: Maybe<Scalars["String"]>;
  pathway1DocsVerified?: Maybe<Scalars["String"]>;
  pathway2DocsVerified?: Maybe<Scalars["String"]>;
  pathway345DocsVerified?: Maybe<Scalars["String"]>;
  step1PassedWithOneOrNoFails?: Maybe<Scalars["String"]>;
  step2CKPassedWithOneOrNoFails?: Maybe<Scalars["String"]>;
};

export type ExceptionRequestChecklistInput = {
  applicantEligiblePathway1: Scalars["String"];
  applicantEligiblePathway2: Scalars["String"];
  applicantEligiblePathway345: Scalars["String"];
  applicantHasNotSubmittedAppForCert: Scalars["String"];
  applicantHasPurchasedToken2021Match: Scalars["String"];
  applicantIsNotCertified: Scalars["String"];
  applicantNotBarredByUSMLE: Scalars["String"];
  applicantNotValidStep2CSScore: Scalars["String"];
  ecfmgRestrictionsDoNotPreventAppExamination: Scalars["String"];
  exceptionApproved: Scalars["String"];
  pathway1DocsVerified: Scalars["String"];
  pathway2DocsVerified: Scalars["String"];
  pathway345DocsVerified: Scalars["String"];
  step1PassedWithOneOrNoFails: Scalars["String"];
  step2CKPassedWithOneOrNoFails: Scalars["String"];
};

export type ExceptionRequestData = {
  __typename?: "ExceptionRequestData";
  documents?: Maybe<Array<Maybe<Document>>>;
  requestText?: Maybe<Scalars["String"]>;
};

export type ExceptionRequestDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  requestText?: InputMaybe<Scalars["String"]>;
};

export type ExceptionRequestInput = {
  data?: InputMaybe<ExceptionRequestDataInput>;
};

export type ExceptionRequestReview = {
  __typename?: "ExceptionRequestReview";
  checklist?: Maybe<ExceptionRequestChecklist>;
  exceptionChecklistState?: Maybe<Scalars["String"]>;
  pathwayChecklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type ExceptionRequestReviewInput = {
  checklist?: InputMaybe<ExceptionRequestChecklistInput>;
  exceptionChecklistState?: InputMaybe<Scalars["String"]>;
  pathwayChecklistState?: InputMaybe<Scalars["String"]>;
};

export type FailedEncounterType = {
  __typename?: "FailedEncounterType";
  encounterId?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type Finance = {
  __typename?: "Finance";
  caseFee?: Maybe<Scalars["Int"]>;
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
};

export type GlDistributionConfig = {
  __typename?: "GLDistributionConfig";
  _id?: Maybe<Scalars["ID"]>;
  applicationType?: Maybe<Scalars["String"]>;
  configName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdBy?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["String"]>;
  exceptionCaseFee?: Maybe<Scalars["Int"]>;
  exceptionCaseInitialIntake?: Maybe<Scalars["Int"]>;
  glAccountCashControl?: Maybe<Scalars["String"]>;
  glAccountEarned?: Maybe<Scalars["String"]>;
  glAccountRefundControl?: Maybe<Scalars["String"]>;
  glAccountUnearned?: Maybe<Scalars["String"]>;
  standardCaseFee?: Maybe<Scalars["Int"]>;
  standardCaseInitialIntake?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type GlDistributionConfigEditInput = {
  configName: Scalars["String"];
};

export type GlDistributionConfigInput = {
  applicationType: Scalars["String"];
  configName: Scalars["String"];
  dateFrom: Scalars["DateString"];
  exceptionCaseFee: Scalars["Int"];
  exceptionCaseInitialIntake: Scalars["Int"];
  glAccountCashControl: Scalars["String"];
  glAccountEarned: Scalars["String"];
  glAccountRefundControl: Scalars["String"];
  glAccountUnearned: Scalars["String"];
  standardCaseFee: Scalars["Int"];
  standardCaseInitialIntake: Scalars["Int"];
};

export type GenericResponseType = {
  __typename?: "GenericResponseType";
  code?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  msg?: Maybe<Scalars["String"]>;
};

export type GraduationType = {
  __typename?: "GraduationType";
  country?: Maybe<Scalars["String"]>;
  gradSchoolId?: Maybe<Scalars["Int"]>;
  isYearEligible?: Maybe<Scalars["Boolean"]>;
  school?: Maybe<Scalars["String"]>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
  year?: Maybe<Scalars["String"]>;
};

export type JobEligibilityChecksResponseType = {
  __typename?: "JobEligibilityChecksResponseType";
  eligibilityPreCheck?: Maybe<JobResponseType>;
  generalEligibility?: Maybe<JobResponseType>;
  jobId?: Maybe<Scalars["String"]>;
  totalTimeTaken?: Maybe<Scalars["Int"]>;
};

export type JobResponseType = {
  __typename?: "JobResponseType";
  failureCount?: Maybe<Scalars["Int"]>;
  jobId?: Maybe<Scalars["String"]>;
  jobStatus?: Maybe<Scalars["String"]>;
  successCount?: Maybe<Scalars["Int"]>;
  timeTaken?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LanguageAssessment = {
  __typename?: "LanguageAssessment";
  applicantProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  staffProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  userResponse?: Maybe<LanguageAssessmentUserResponse>;
};

export type LanguageAssessmentChecklist = {
  __typename?: "LanguageAssessmentChecklist";
  documentCompleteLegible?: Maybe<Scalars["String"]>;
  examDateValidation?: Maybe<Scalars["String"]>;
  nameValid?: Maybe<Scalars["String"]>;
  scoreAcceptable?: Maybe<Scalars["String"]>;
  scoreConfirmed?: Maybe<Scalars["String"]>;
};

export type LanguageAssessmentChecklistInput = {
  documentCompleteLegible: Scalars["String"];
  examDateValidation: Scalars["String"];
  nameValid: Scalars["String"];
  scoreAcceptable: Scalars["String"];
  scoreConfirmed: Scalars["String"];
};

export type LanguageAssessmentData = {
  __typename?: "LanguageAssessmentData";
  _id?: Maybe<Scalars["ID"]>;
  candidateId?: Maybe<Scalars["String"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars["String"]>;
};

export type LanguageAssessmentDataInput = {
  candidateId?: InputMaybe<Scalars["String"]>;
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars["String"]>;
};

export type LanguageAssessmentInput = {
  applicantProvidedData?: InputMaybe<
    Array<InputMaybe<LanguageAssessmentTypeInput>>
  >;
  userResponse?: InputMaybe<LanguageAssessmentUserResponseInput>;
};

export type LanguageAssessmentReview = {
  __typename?: "LanguageAssessmentReview";
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type LanguageAssessmentReviewInput = {
  checklist?: InputMaybe<LanguageAssessmentChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type LanguageAssessmentType = {
  __typename?: "LanguageAssessmentType";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<LanguageAssessmentData>;
  review?: Maybe<LanguageAssessmentReview>;
};

export type LanguageAssessmentTypeInput = {
  data?: InputMaybe<LanguageAssessmentDataInput>;
};

export type LanguageAssessmentUserResponse = {
  __typename?: "LanguageAssessmentUserResponse";
  hasPassedExam?: Maybe<Scalars["Boolean"]>;
};

export type LanguageAssessmentUserResponseInput = {
  hasPassedExam?: InputMaybe<Scalars["Boolean"]>;
};

export type LastAccessedView = {
  __typename?: "LastAccessedView";
  assignedToMe?: Maybe<Scalars["Boolean"]>;
  recordsPerPage?: Maybe<Scalars["Int"]>;
  viewId?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type LastAccessedViewInput = {
  assignedToMe?: InputMaybe<Scalars["Boolean"]>;
  recordsPerPage?: InputMaybe<Scalars["Int"]>;
  viewId?: InputMaybe<Scalars["CosmosObjectIdString"]>;
};

export type License = {
  __typename?: "License";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<LicenseData>;
  review?: Maybe<LicenseReview>;
};

export type LicenseChecklist = {
  __typename?: "LicenseChecklist";
  licensureApplicantLicenseOnPoint?: Maybe<Scalars["String"]>;
  licensureCompleteLegible?: Maybe<Scalars["String"]>;
  licensureDisciplinaryAction?: Maybe<Scalars["String"]>;
  licensureDocumentIssueDateAcceptable?: Maybe<Scalars["String"]>;
  licensureIssuedAppropriateAuthority?: Maybe<Scalars["String"]>;
  licensureNameValidation?: Maybe<Scalars["String"]>;
  licensureTranslationStatus?: Maybe<Scalars["String"]>;
  licensureUnrestrictedLicense?: Maybe<Scalars["String"]>;
  verificationActiveLicense?: Maybe<Scalars["String"]>;
  verificationAuthorized?: Maybe<Scalars["String"]>;
  verificationGoodStanding?: Maybe<Scalars["String"]>;
  verificationPrimarySource?: Maybe<Scalars["String"]>;
};

export type LicenseChecklistInput = {
  licensureApplicantLicenseOnPoint: Scalars["String"];
  licensureCompleteLegible: Scalars["String"];
  licensureDisciplinaryAction: Scalars["String"];
  licensureDocumentIssueDateAcceptable: Scalars["String"];
  licensureIssuedAppropriateAuthority: Scalars["String"];
  licensureNameValidation: Scalars["String"];
  licensureTranslationStatus: Scalars["String"];
  licensureUnrestrictedLicense: Scalars["String"];
  verificationActiveLicense: Scalars["String"];
  verificationAuthorized: Scalars["String"];
  verificationGoodStanding: Scalars["String"];
  verificationPrimarySource: Scalars["String"];
};

export type LicenseData = {
  __typename?: "LicenseData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  hasDisciplinaryAction?: Maybe<Scalars["Boolean"]>;
  isCurrentlyLicensed?: Maybe<Scalars["Boolean"]>;
  licenseExpiryDate?: Maybe<Scalars["String"]>;
  licenseIssueDate?: Maybe<Scalars["String"]>;
  licensureDocumentStatus?: Maybe<Scalars["String"]>;
  regulatoryAuthorityRef?: Maybe<RegulatoryAuthorityResponseType>;
};

export type LicenseDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  hasDisciplinaryAction?: InputMaybe<Scalars["Boolean"]>;
  isCurrentlyLicensed?: InputMaybe<Scalars["Boolean"]>;
  licenseExpiryDate?: InputMaybe<Scalars["String"]>;
  licenseIssueDate?: InputMaybe<Scalars["String"]>;
  licensureDocumentStatus?: InputMaybe<Scalars["String"]>;
  regulatoryAuthorityRef?: InputMaybe<RegulatoryAuthorityInput>;
};

export type LicenseInput = {
  data?: InputMaybe<LicenseDataInput>;
};

export type LicenseReview = {
  __typename?: "LicenseReview";
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type LicenseReviewInput = {
  checklist?: InputMaybe<LicenseChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type MiniCexAdditionalInfoInput = {
  additionalInfoText?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<
    Array<InputMaybe<MiniCexCategoryAdditionalInfoInput>>
  >;
};

export type MiniCexAssessmentType = {
  __typename?: "MiniCEXAssessmentType";
  categories?: Maybe<Array<Maybe<AssessmentCategoryType>>>;
  output?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexAttestationType = {
  __typename?: "MiniCEXAttestationType";
  attestedAt?: Maybe<Array<Maybe<Scalars["DateString"]>>>;
  hasApplicantEnrolledInClinicalRotation?: Maybe<Scalars["String"]>;
};

export type MiniCexByPhysicianFilterCaseManagerInput = {
  email: Scalars["String"];
  responseType?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCategoryAdditionalInfoInput = {
  name?: InputMaybe<Scalars["String"]>;
  reasonText?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCategoryScoreInput = {
  name?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

export type MiniCexCategoryType = {
  __typename?: "MiniCEXCategoryType";
  isAdditionalInfoRequired?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  reasonText?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type MiniCexChecklist = {
  __typename?: "MiniCEXChecklist";
  minicexComplete?: Maybe<Scalars["String"]>;
  minicexScoreAcceptable?: Maybe<Scalars["String"]>;
};

export type MiniCexChecklistInput = {
  minicexComplete: Scalars["String"];
  minicexScoreAcceptable: Scalars["String"];
};

export type MiniCexConfigMgmtType = {
  __typename?: "MiniCEXConfigMgmtType";
  config?: Maybe<MiniCexConfigRecordType>;
  configName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdUser?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateFrom?: Maybe<Scalars["DateString"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  minicexConfigRef?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedUser?: Maybe<Scalars["String"]>;
};

export type MiniCexConfigRecordType = {
  __typename?: "MiniCEXConfigRecordType";
  deadline?: Maybe<Array<Maybe<MiniCexDeadlineConfigType>>>;
  evaluationCategory?: Maybe<Array<Maybe<MiniCexEvaluationCategoryConfigType>>>;
  pathwaySeason?: Maybe<Scalars["String"]>;
  submission?: Maybe<MiniCexSubmissionConfigType>;
};

export type MiniCexConfigType = {
  __typename?: "MiniCEXConfigType";
  config?: Maybe<MiniCexConfigRecordType>;
  minicexConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type MiniCexCountryInput = {
  name?: InputMaybe<Scalars["String"]>;
  ref?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCountryType = {
  __typename?: "MiniCEXCountryType";
  name?: Maybe<Scalars["String"]>;
  ref?: Maybe<Scalars["String"]>;
};

export type MiniCexData = {
  __typename?: "MiniCEXData";
  _id?: Maybe<Scalars["ID"]>;
  acknowledgedTimestamp?: Maybe<Scalars["DateString"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type MiniCexDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
};

export type MiniCexDeadlineConfigType = {
  __typename?: "MiniCEXDeadlineConfigType";
  deadlineInDays?: Maybe<Scalars["Int"]>;
  deadlineName?: Maybe<Scalars["String"]>;
};

export type MiniCexEncounterInput = {
  encounterDate?: InputMaybe<Scalars["String"]>;
  encounterSetting?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<MiniCexFacilityInput>;
  patient?: InputMaybe<MiniCexPatientInput>;
};

export type MiniCexEncounterType = {
  __typename?: "MiniCEXEncounterType";
  encounterDate?: Maybe<Scalars["String"]>;
  encounterSetting?: Maybe<Scalars["String"]>;
  facility?: Maybe<MiniCexFacilityType>;
  patient?: Maybe<MiniCexPatientType>;
};

export type MiniCexEvaluationCategoryConfigType = {
  __typename?: "MiniCEXEvaluationCategoryConfigType";
  additionalInfo?: Maybe<CategoryAdditionalInfoType>;
  categoryName?: Maybe<Scalars["String"]>;
  qualification?: Maybe<CategoryQualificationType>;
};

export type MiniCexEvaluationInput = {
  categories?: InputMaybe<Array<InputMaybe<MiniCexCategoryScoreInput>>>;
};

export type MiniCexEvaluationType = {
  __typename?: "MiniCEXEvaluationType";
  additionalInfoText?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<MiniCexCategoryType>>>;
};

export type MiniCexFacilityInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<MiniCexCountryInput>;
  name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type MiniCexFacilityType = {
  __typename?: "MiniCEXFacilityType";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<MiniCexCountryType>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type MiniCexFormProgressType = {
  __typename?: "MiniCEXFormProgressType";
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type MiniCexFormType = {
  __typename?: "MiniCEXFormType";
  attestation?: Maybe<MiniCexAttestationType>;
  encounter?: Maybe<MiniCexEncounterType>;
  evaluation?: Maybe<MiniCexEvaluationType>;
  progress?: Maybe<Array<Maybe<MiniCexFormProgressType>>>;
};

export type MiniCexListFilterApplicantInput = {
  responseType: Scalars["String"];
};

export type MiniCexListFilterCaseManagerInput = {
  responseType: Scalars["String"];
  usmleId: Scalars["String"];
};

export type MiniCexListFilterPhysicianInput = {
  responseType: Scalars["String"];
};

export type MiniCexNotificationType = {
  __typename?: "MiniCEXNotificationType";
  name?: Maybe<Scalars["String"]>;
  recipient?: Maybe<UserType>;
  status?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexPatientInput = {
  age?: InputMaybe<Scalars["Int"]>;
  ageMonth?: InputMaybe<Scalars["String"]>;
  ageYear?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Scalars["String"]>;
};

export type MiniCexPatientType = {
  __typename?: "MiniCEXPatientType";
  age?: Maybe<Scalars["Int"]>;
  ageMonth?: Maybe<Scalars["String"]>;
  ageYear?: Maybe<Scalars["Int"]>;
  gender?: Maybe<Scalars["String"]>;
};

export type MiniCexResponseType = {
  __typename?: "MiniCEXResponseType";
  dataVersion?: Maybe<Scalars["Int"]>;
  encounterId?: Maybe<Scalars["String"]>;
  form?: Maybe<MiniCexFormType>;
  minicexConfig?: Maybe<MiniCexConfigType>;
  minicexId?: Maybe<Scalars["String"]>;
  summary?: Maybe<MiniCexSummaryType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type MiniCexReview = {
  __typename?: "MiniCEXReview";
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  isLocked?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexReviewInput = {
  checklist?: InputMaybe<MiniCexChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type MiniCexStateModelType = {
  __typename?: "MiniCEXStateModelType";
  state?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexSubmissionConfigType = {
  __typename?: "MiniCEXSubmissionConfigType";
  maxRequestPerPhysician?: Maybe<Scalars["Int"]>;
  totalRequestCount?: Maybe<Scalars["Int"]>;
};

export type MiniCexSummaryType = {
  __typename?: "MiniCEXSummaryType";
  acceptedAt?: Maybe<Scalars["DateString"]>;
  allowedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  applicantLastName?: Maybe<Scalars["String"]>;
  applicantRestOfName?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  encounterId?: Maybe<Scalars["String"]>;
  expiresAt?: Maybe<Scalars["DateString"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  minicexId?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Maybe<MiniCexNotificationType>>>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  physicianEmail?: Maybe<Scalars["String"]>;
  physicianLastNameProvidedByApplicant?: Maybe<Scalars["String"]>;
  physicianLastNameProvidedByPhysician?: Maybe<Scalars["String"]>;
  physicianRestOfNameProvidedByApplicant?: Maybe<Scalars["String"]>;
  physicianRestOfNameProvidedByPhysician?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  rejectionReasonComments?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateModel?: Maybe<Array<Maybe<MiniCexStateModelType>>>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type MiniCexSurveyInput = {
  questions?: InputMaybe<Array<InputMaybe<MiniCexSurveyQuestionInput>>>;
};

export type MiniCexSurveyQuestionInput = {
  questionId?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type Mutation = {
  __typename?: "Mutation";
  AdminPortal_events_republish?: Maybe<
    Array<Maybe<EventRepublishResponseType>>
  >;
  ApplicantPortal_MiniCEX_add?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_MiniCEX_remove?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_checkPaymentExists?: Maybe<Scalars["Boolean"]>;
  ApplicantPortal_login?: Maybe<ApplicantPortalLoginResponseType>;
  ApplicantPortal_pathway1a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway1b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway6?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway345?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathwayX?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_runEligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  AuthorityPortal_attest?: Maybe<Scalars["String"]>;
  AuthorityPortal_reject?: Maybe<Scalars["String"]>;
  CasePortal_AuthorityMgmt_syncAccountFromB2C?: Maybe<ResponseStatusType>;
  CasePortal_MiniCEX_cancel?: Maybe<MiniCexResponseType>;
  CasePortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  CasePortal_PhyMgmt_block?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editDoB?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editName?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optIn?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optOut?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_syncRegistration?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_unblock?: Maybe<PhysicianResponseType>;
  CasePortal_SubmissionSummary?: Maybe<Scalars["String"]>;
  CasePortal_addCaseNote?: Maybe<CaseResponseType>;
  CasePortal_additionalDocuments?: Maybe<CaseResponseType>;
  CasePortal_archivedCase_SubmissionSummary?: Maybe<Scalars["String"]>;
  CasePortal_assignEligibilityReviewTask?: Maybe<CaseResponseType>;
  CasePortal_assignQAReviewTask?: Maybe<CaseResponseType>;
  CasePortal_case_archive?: Maybe<CaseResponseStatusType>;
  CasePortal_case_purge?: Maybe<CaseResponseStatusType>;
  CasePortal_checkCaseExists?: Maybe<Scalars["Boolean"]>;
  CasePortal_convertToExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_createExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_glDistributionReport?: Maybe<
    Array<Maybe<GlDistributionReportResponseType>>
  >;
  CasePortal_issuePaperCheck?: Maybe<GenericResponseType>;
  CasePortal_languageAssessment_setReview?: Maybe<CaseResponseType>;
  CasePortal_overridePathway?: Maybe<CaseResponseType>;
  CasePortal_pathway1_deleteRecord?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setData?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway2_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_unlockReview?: Maybe<CaseResponseType>;
  CasePortal_pathway345_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathwayX_setReview?: Maybe<CaseResponseType>;
  CasePortal_process_generalEligibility?: Maybe<CaseResponseType>;
  CasePortal_qa_acceptDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_approve?: Maybe<CaseResponseType>;
  CasePortal_qa_overrideDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_reject?: Maybe<CaseResponseType>;
  CasePortal_qa_reworkRequired?: Maybe<CaseResponseType>;
  CasePortal_reopenCase?: Maybe<CaseResponseType>;
  CasePortal_review_reworkRequired?: Maybe<CaseResponseType>;
  FeatureFlag_SASToken?: Maybe<SasToken>;
  GLDistributionConfig_create?: Maybe<GlDistributionConfig>;
  GLDistributionConfig_deactivate?: Maybe<ResponseStatusType>;
  GLDistributionConfig_edit?: Maybe<GlDistributionConfig>;
  Job_processEligibilityChecks?: Maybe<JobEligibilityChecksResponseType>;
  PhysicianPortal_Mgmt_optOut?: Maybe<PhysicianResponseType>;
  PhysicianPortal_Mgmt_optOut_noauth?: Maybe<ResponseStatusType>;
  PhysicianPortal_Mgmt_setProfile?: Maybe<PhysicianResponseType>;
  PhysicianPortal_MiniCEX_accept?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_attest?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_complete?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_get?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setAdditionalInfo?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEncounter?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEvaluation?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setSurvey?: Maybe<ResponseStatusType>;
  PhysicianRegAuthority?: Maybe<
    Array<Maybe<PhysicianRegAuthorityResponseType>>
  >;
  /** IGNORE: Dummy field necessary for the Mutation type to be valid */
  _empty?: Maybe<Scalars["String"]>;
  addDashboard?: Maybe<Dashboard>;
  addOETViewFilters?: Maybe<Dashboard>;
  addViewFilters?: Maybe<Dashboard>;
  deleteDashboard?: Maybe<DeleteDashboardResponse>;
  deleteOETViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilters?: Maybe<DeleteDashboardResponse>;
  requestRefund?: Maybe<RequestPayload>;
  requestSecureToken?: Maybe<TokenPayload>;
  saveLastAccessedView?: Maybe<Dashboard>;
  saveOETViewFilters?: Maybe<Dashboard>;
  saveViewFilters?: Maybe<Dashboard>;
  uploadDocument?: Maybe<SasToken>;
  validateEmail?: Maybe<ValidationResult>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAdminPortal_Events_RepublishArgs = {
  parentIds: Array<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_AddArgs = {
  input: AddMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_RemoveArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_LoginArgs = {
  password: Scalars["String"];
  userid: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1aArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1bArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2aArgs = {
  input: Pathway2Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2bArgs = {
  input?: InputMaybe<Pathway2Input>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_PathwayXArgs = {
  input: ExceptionRequestInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_AttestArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_RejectArgs = {
  dataVersion: Scalars["Int"];
  rejectionReason: Scalars["String"];
  rejectionReasonText?: InputMaybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AuthorityMgmt_SyncAccountFromB2CArgs = {
  userName?: InputMaybe<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_CancelArgs = {
  encounterId: Scalars["String"];
  isMiniCEXAssessmentResetConfirmed: Scalars["Boolean"];
  minicexDataVersion: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_RejectArgs = {
  encounterId: Scalars["String"];
  minicexDataVersion: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_BlockArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditDoBArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input: PhysicianDoBInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditNameArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianNameInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptInArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptOutArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_SyncRegistrationArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_UnblockArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_SubmissionSummaryArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AddCaseNoteArgs = {
  dataVersion: Scalars["Int"];
  input: CaseNoteInput;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AdditionalDocumentsArgs = {
  dataVersion: Scalars["Int"];
  input: Array<AdditionalDocumentInput>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ArchivedCase_SubmissionSummaryArgs = {
  caseId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignEligibilityReviewTaskArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignQaReviewTaskArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_ArchiveArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_PurgeArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CheckCaseExistsArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ConvertToExceptionCaseArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CreateExceptionCaseArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_GlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_IssuePaperCheckArgs = {
  amount: Scalars["Int"];
  dataVersion?: InputMaybe<Scalars["Int"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_LanguageAssessment_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: LanguageAssessmentReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_OverridePathwayArgs = {
  dataVersion: Scalars["Int"];
  input: OverridePathwayInput;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_DeleteRecordArgs = {
  dataVersion: Scalars["Int"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetDataArgs = {
  dataVersion: Scalars["Int"];
  input: LicenseDataInput;
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: LicenseReviewInput;
  isComplete: Scalars["Boolean"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway2_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: CsExamReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: MiniCexReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_UnlockReviewArgs = {
  dataVersion: Scalars["Int"];
  reason?: InputMaybe<Scalars["String"]>;
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway345_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: EducationReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PathwayX_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: ExceptionRequestReviewInput;
  isComplete: Scalars["Boolean"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Process_GeneralEligibilityArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_AcceptDecisionArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ApproveArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_OverrideDecisionArgs = {
  comments: Scalars["String"];
  dataVersion: Scalars["Int"];
  decision: Scalars["String"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_RejectArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ReworkRequiredArgs = {
  dataVersion: Scalars["Int"];
  processGEChecksOnQARework: Scalars["Boolean"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ReopenCaseArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Review_ReworkRequiredArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_CreateArgs = {
  input: GlDistributionConfigInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_DeactivateArgs = {
  _id: Scalars["ID"];
  version: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_EditArgs = {
  _id: Scalars["ID"];
  input: GlDistributionConfigEditInput;
  version: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOutArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOut_NoauthArgs = {
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
  token: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_SetProfileArgs = {
  dataVersion: Scalars["Int"];
  input: PhysicianProfileInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AcceptArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AttestArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input?: InputMaybe<AttestMiniCexInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_CompleteArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  preSubmissionSurvey?: InputMaybe<PhysicianMiniCexPreSubmissionSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_GetArgs = {
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_RejectArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: RejectMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetAdditionalInfoArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexAdditionalInfoInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEncounterArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexEncounterInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEvaluationArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexEvaluationInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetSurveyArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input?: InputMaybe<MiniCexSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddDashboardArgs = {
  input: DashboardInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteDashboardArgs = {
  _id: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteOetViewFilterArgs = {
  oetViewId: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFilterArgs = {
  viewId: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFiltersArgs = {
  dashboardId: Scalars["ID"];
  dashboardType: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestRefundArgs = {
  requestArgument?: InputMaybe<RefundRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestSecureTokenArgs = {
  requestArgument?: InputMaybe<TokenRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveLastAccessedViewArgs = {
  input: LastAccessedViewInput;
  viewType: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationUploadDocumentArgs = {
  fileName: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationValidateEmailArgs = {
  email: Scalars["String"];
};

export type OetInput = {
  candidateId: Scalars["String"];
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  examDate?: InputMaybe<Scalars["String"]>;
  examineeFirstName?: InputMaybe<Scalars["String"]>;
  examineeLastName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  isMatched?: InputMaybe<Scalars["Boolean"]>;
  listeningScore?: InputMaybe<Scalars["Float"]>;
  readingScore?: InputMaybe<Scalars["Float"]>;
  result?: InputMaybe<Scalars["String"]>;
  schemaVersion: Scalars["String"];
  speakingScore?: InputMaybe<Scalars["Float"]>;
  testingCenter?: InputMaybe<Scalars["String"]>;
  usmleId?: InputMaybe<Scalars["String"]>;
  writingScore?: InputMaybe<Scalars["Float"]>;
};

export type OetViewFilters = {
  __typename?: "OETViewFilters";
  matchState?: Maybe<Scalars["Boolean"]>;
  resultState?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type OetViewFiltersInput = {
  matchState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  resultState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type OetViews = {
  __typename?: "OETViews";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  viewFilters?: Maybe<OetViewFilters>;
  viewName?: Maybe<Scalars["String"]>;
};

export type OetViewsInput = {
  _id?: InputMaybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewFilters?: InputMaybe<OetViewFiltersInput>;
  viewName?: InputMaybe<Scalars["String"]>;
};

export type OetPortalResponseType = {
  __typename?: "OetPortalResponseType";
  oetscores?: Maybe<Array<Maybe<OetResponseType>>>;
};

export type OetResponseType = {
  __typename?: "OetResponseType";
  _id?: Maybe<Scalars["String"]>;
  candidateId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdUser?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  examDate?: Maybe<Scalars["String"]>;
  examineeFirstName?: Maybe<Scalars["String"]>;
  examineeLastName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  listeningScore?: Maybe<Scalars["String"]>;
  matched?: Maybe<Scalars["String"]>;
  matchedCandidateId?: Maybe<Scalars["String"]>;
  matchedCaseState?: Maybe<Scalars["String"]>;
  matchedDateOfBirth?: Maybe<Scalars["String"]>;
  matchedEnglishEligibilityStatus?: Maybe<Scalars["String"]>;
  matchedFirstName?: Maybe<Scalars["String"]>;
  matchedLastName?: Maybe<Scalars["String"]>;
  matchedUsmleId?: Maybe<Scalars["String"]>;
  matchedexamDate?: Maybe<Scalars["String"]>;
  readingScore?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
  speakingScore?: Maybe<Scalars["String"]>;
  testingCenter?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedUser?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
  writingScore?: Maybe<Scalars["String"]>;
};

export type OverridePathwayInput = {
  currentPathway: Scalars["String"];
  usmleId: Scalars["String"];
};

export type PastAttemptsType = {
  __typename?: "PastAttemptsType";
  applicationType?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  output?: Maybe<Scalars["String"]>;
  pathway?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["String"]>;
  refundIssued?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  submittedAt?: Maybe<Scalars["DateString"]>;
};

export type Pathway1 = {
  __typename?: "Pathway1";
  applicantProvidedData?: Maybe<Array<Maybe<License>>>;
  staffProvidedData?: Maybe<Array<Maybe<License>>>;
  userResponse?: Maybe<Pathway1UserResponse>;
};

export type Pathway1Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  userResponse?: InputMaybe<Pathway1UserResponseInput>;
};

export type Pathway1UserResponse = {
  __typename?: "Pathway1UserResponse";
  isCurrentlyLicensed?: Maybe<Scalars["Boolean"]>;
  wasLicensedInRecentPast?: Maybe<Scalars["Boolean"]>;
};

export type Pathway1UserResponseInput = {
  isCurrentlyLicensed?: InputMaybe<Scalars["Boolean"]>;
  wasLicensedInRecentPast?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway2 = {
  __typename?: "Pathway2";
  applicantProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  staffProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  userResponse?: Maybe<Pathway2UserResponse>;
};

export type Pathway2Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<CsExamInput>>>;
  userResponse?: InputMaybe<Pathway2UserResponseInput>;
};

export type Pathway2UserResponse = {
  __typename?: "Pathway2UserResponse";
  hasPassedCSExamInRecentPast?: Maybe<Scalars["Boolean"]>;
  hasPassedFromQualifiedSchoolInRecentPast?: Maybe<Scalars["Boolean"]>;
};

export type Pathway2UserResponseInput = {
  hasPassedCSExamInRecentPast?: InputMaybe<Scalars["Boolean"]>;
  hasPassedFromQualifiedSchoolInRecentPast?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway6 = {
  __typename?: "Pathway6";
  applicantProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  staffProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  userResponse?: Maybe<Pathway6UserResponse>;
};

export type Pathway6MinicexListArgs = {
  filter: MiniCexListFilterCaseManagerInput;
};

export type Pathway6Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<Pathway6MiniCexInput>>>;
  userResponse?: InputMaybe<Pathway6UserResponseInput>;
};

export type Pathway6MiniCexInput = {
  data?: InputMaybe<MiniCexDataInput>;
};

export type Pathway6MiniCexType = {
  __typename?: "Pathway6MiniCEXType";
  _id?: Maybe<Scalars["ID"]>;
  areAllMiniCEXComplete?: Maybe<Scalars["Boolean"]>;
  assessment?: Maybe<MiniCexAssessmentType>;
  data?: Maybe<MiniCexData>;
  review?: Maybe<MiniCexReview>;
};

export type Pathway6UserResponse = {
  __typename?: "Pathway6UserResponse";
  hasAgreedToProvidePhysicianInfo?: Maybe<Scalars["Boolean"]>;
};

export type Pathway6UserResponseInput = {
  hasAgreedToProvidePhysicianInfo?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway345 = {
  __typename?: "Pathway345";
  applicantProvidedData?: Maybe<Array<Maybe<Education>>>;
  staffProvidedData?: Maybe<Array<Maybe<Education>>>;
  userResponse?: Maybe<Pathway345UserResponse>;
};

export type Pathway345Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  userResponse?: InputMaybe<Pathway345UserResponseInput>;
};

export type Pathway345UserResponse = {
  __typename?: "Pathway345UserResponse";
  isGradDateInAcceptableRange?: Maybe<Scalars["Boolean"]>;
  isGradSchoolEligible?: Maybe<Scalars["Boolean"]>;
};

export type Pathway345UserResponseInput = {
  isGradDateInAcceptableRange?: InputMaybe<Scalars["Boolean"]>;
  isGradSchoolEligible?: InputMaybe<Scalars["Boolean"]>;
};

export type Payment = {
  __typename?: "Payment";
  amt?: Maybe<Scalars["Int"]>;
  pnRef?: Maybe<Scalars["String"]>;
  transTime?: Maybe<Scalars["DateString"]>;
};

export type PhysicianByMiniCexFilterCaseManagerInput = {
  responseType?: InputMaybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

export type PhysicianConfigRecordType = {
  __typename?: "PhysicianConfigRecordType";
  minicex?: Maybe<PhysicianMiniCexConfigType>;
};

export type PhysicianConfigType = {
  __typename?: "PhysicianConfigType";
  config?: Maybe<PhysicianConfigRecordType>;
  physicianConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type PhysicianDoBInput = {
  dateOfBirth: Scalars["Date"];
};

export type PhysicianFaimerSurveyQuestionType = {
  __typename?: "PhysicianFaimerSurveyQuestionType";
  comments?: Maybe<Scalars["String"]>;
  questionDescription?: Maybe<Scalars["String"]>;
  questionId?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["String"]>;
};

export type PhysicianFaimerSurveyType = {
  __typename?: "PhysicianFaimerSurveyType";
  completedAt?: Maybe<Scalars["Date"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  questions?: Maybe<Array<Maybe<PhysicianFaimerSurveyQuestionType>>>;
};

export type PhysicianLicenseInput = {
  _id?: InputMaybe<Scalars["String"]>;
  countryName: Scalars["String"];
  licenseNumber: Scalars["String"];
  orgName: Scalars["String"];
  physicianRegAuthorityRef: Scalars["Int"];
};

export type PhysicianLicenseRecordType = {
  __typename?: "PhysicianLicenseRecordType";
  _id?: Maybe<Scalars["String"]>;
  addedAt?: Maybe<Scalars["DateString"]>;
  countryName?: Maybe<Scalars["String"]>;
  licenseNumber?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
  physicianRegAuthorityRef?: Maybe<Scalars["Int"]>;
  removedAt?: Maybe<Scalars["DateString"]>;
};

export type PhysicianMiniCexConfigType = {
  __typename?: "PhysicianMiniCEXConfigType";
  maxApplicantsAllowedToBeEvaluatedByPhysician?: Maybe<Scalars["Int"]>;
};

export type PhysicianMiniCexPreSubmissionSurveyInput = {
  questions?: InputMaybe<
    Array<InputMaybe<PhysicianMiniCexPreSubmissionSurveyQuestionInput>>
  >;
};

export type PhysicianMiniCexPreSubmissionSurveyQuestionInput = {
  comments?: InputMaybe<Scalars["String"]>;
  questionDescription?: InputMaybe<Scalars["String"]>;
  questionId?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
};

export type PhysicianMinicexAccountType = {
  __typename?: "PhysicianMinicexAccountType";
  accountStatus?: Maybe<Scalars["String"]>;
  accountStatusComments?: Maybe<Scalars["String"]>;
  faimerSurveyCurrentPathwaySeason?: Maybe<PhysicianFaimerSurveyType>;
  optOut?: Maybe<PhysicianOptOutType>;
  profileStatus?: Maybe<Scalars["String"]>;
  profileStatusComments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tags?: Maybe<PhysicianTagsType>;
};

export type PhysicianNameInput = {
  email: Scalars["String"];
  lastName: Scalars["String"];
  restOfName?: InputMaybe<Scalars["String"]>;
};

export type PhysicianOptOutInput = {
  reason?: InputMaybe<Scalars["String"]>;
  reasonComments?: InputMaybe<Scalars["String"]>;
};

export type PhysicianOptOutType = {
  __typename?: "PhysicianOptOutType";
  lastAccountStatus?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  reasonComments?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type PhysicianPortalResponseType = {
  __typename?: "PhysicianPortalResponseType";
  minicex?: Maybe<MiniCexResponseType>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  physician?: Maybe<PhysicianResponseType>;
};

export type PhysicianPortalResponseTypeMinicexArgs = {
  encounterId: Scalars["String"];
};

export type PhysicianPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterPhysicianInput;
};

export type PhysicianProfileInput = {
  dateOfBirth: Scalars["Date"];
  isHoldingFacultyAppointment: Scalars["String"];
  lastName: Scalars["String"];
  licenses?: InputMaybe<Array<InputMaybe<PhysicianLicenseInput>>>;
  medicalSpecialty?: InputMaybe<Scalars["String"]>;
  restOfName?: InputMaybe<Scalars["String"]>;
  secondaryEmails?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type PhysicianProfileType = {
  __typename?: "PhysicianProfileType";
  dateOfBirth?: Maybe<Scalars["Date"]>;
  isHoldingFacultyAppointment?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  licenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  medicalSpecialty?: Maybe<Scalars["String"]>;
  removedLicenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  restOfName?: Maybe<Scalars["String"]>;
  secondaryEmails?: Maybe<Array<Maybe<Scalars["String"]>>>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type PhysicianResponseType = {
  __typename?: "PhysicianResponseType";
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  allowedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  applicantsEvaluatedByPhysician?: Maybe<Array<Maybe<A_Applicant>>>;
  dataVersion?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  minicex?: Maybe<PhysicianMinicexAccountType>;
  oid?: Maybe<Scalars["String"]>;
  physicianConfig?: Maybe<PhysicianConfigType>;
  physicianId?: Maybe<Scalars["String"]>;
  profile?: Maybe<PhysicianProfileType>;
};

export type PhysicianResponseTypeApplicantsEvaluatedByPhysicianArgs = {
  email?: InputMaybe<Scalars["String"]>;
};

export type PhysicianTagsType = {
  __typename?: "PhysicianTagsType";
  actionTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PhysiciansByArchivedMinicexFilterCaseManagerInput = {
  caseId: Scalars["String"];
  responseType?: InputMaybe<Scalars["String"]>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type Query = {
  __typename?: "Query";
  /** IGNORE: Dummy field necessary for the Query type to be valid */
  _empty?: Maybe<Scalars["String"]>;
  applicantPortal?: Maybe<ApplicantPortalResponseType>;
  archivedCase?: Maybe<ArchivedCaseType>;
  archivedCases?: Maybe<Array<Maybe<ArchivedCaseSummaryType>>>;
  authorityPortal?: Maybe<AuthorityPortalResponseType>;
  casePortal?: Maybe<CasePortalResponseType>;
  country?: Maybe<Array<Maybe<CountryResponseType>>>;
  downloadDocument?: Maybe<SasToken>;
  getDashboards?: Maybe<Array<Maybe<Dashboard>>>;
  oscexam?: Maybe<Array<Maybe<OscexamResponseType>>>;
  physicianPortal?: Maybe<PhysicianPortalResponseType>;
  physicianRegAuthority?: Maybe<
    Array<Maybe<PhysicianRegAuthorityResponseType>>
  >;
  physiciansByArchivedMinicex?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<RegulatoryAuthorityResponseType>>>;
  school?: Maybe<Array<Maybe<SchoolResponseType>>>;
  serverDate: Scalars["DateString"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCaseArgs = {
  caseId: Scalars["String"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCasesArgs = {
  filter: ArchivedCaseFilterInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryDownloadDocumentArgs = {
  fileName: Scalars["String"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysiciansByArchivedMinicexArgs = {
  filter: PhysiciansByArchivedMinicexFilterCaseManagerInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QuerySchoolArgs = {
  pathway?: InputMaybe<Scalars["String"]>;
};

export type Refund = {
  __typename?: "Refund";
  amt?: Maybe<Scalars["Int"]>;
  pnRef?: Maybe<Scalars["String"]>;
  transTime?: Maybe<Scalars["DateString"]>;
};

export type RefundRequestArgument = {
  amount?: InputMaybe<Scalars["String"]>;
  caseWorker?: InputMaybe<Scalars["String"]>;
  usmleID?: InputMaybe<Scalars["String"]>;
};

export type RejectMiniCexInput = {
  rejectionReason: Scalars["String"];
  rejectionReasonComments?: InputMaybe<Scalars["String"]>;
};

export type RequestPayload = {
  __typename?: "RequestPayload";
  pnRef?: Maybe<Scalars["String"]>;
  responseMessage?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ResponseStatusType = {
  __typename?: "ResponseStatusType";
  code?: Maybe<Scalars["String"]>;
  msg?: Maybe<Scalars["String"]>;
};

export type RestrictionType = {
  __typename?: "RestrictionType";
  comments?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  user?: Maybe<Scalars["String"]>;
};

export type SasToken = {
  __typename?: "SASToken";
  token?: Maybe<Token>;
};

export type SchoolEligibilityType = {
  __typename?: "SchoolEligibilityType";
  isSchoolEMSWPMember?: Maybe<Scalars["Boolean"]>;
  schoolPathway?: Maybe<Scalars["String"]>;
};

export type SchoolInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type SchoolResponseType = {
  __typename?: "SchoolResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SearchOetInput = {
  candidateId?: InputMaybe<Scalars["String"]>;
  examineeFirstName?: InputMaybe<Scalars["String"]>;
  examineeLastName?: InputMaybe<Scalars["String"]>;
  matched?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  output?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type Token = {
  __typename?: "Token";
  token: Scalars["String"];
  uri: Scalars["String"];
};

export type TokenPayload = {
  __typename?: "TokenPayload";
  alreadyPaid?: Maybe<Scalars["String"]>;
  errorDetails?: Maybe<Scalars["String"]>;
  secureToken?: Maybe<Scalars["String"]>;
  secureTokenId?: Maybe<Scalars["String"]>;
};

export type TokenRequestArgument = {
  acknowledgeTimestamp?: InputMaybe<Scalars["String"]>;
  amount?: InputMaybe<Scalars["Float"]>;
  billToCity?: InputMaybe<Scalars["String"]>;
  billToFirstName?: InputMaybe<Scalars["String"]>;
  billToLastName?: InputMaybe<Scalars["String"]>;
  billToMiddleName?: InputMaybe<Scalars["String"]>;
  billToState?: InputMaybe<Scalars["String"]>;
  billToStreet?: InputMaybe<Scalars["String"]>;
  billToStreet2?: InputMaybe<Scalars["String"]>;
  billToZip?: InputMaybe<Scalars["String"]>;
  usmleID?: InputMaybe<Scalars["String"]>;
};

export type UiComponentConfigType = {
  __typename?: "UIComponentConfigType";
  componentName?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type UiConfigRecordType = {
  __typename?: "UIConfigRecordType";
  pages?: Maybe<Array<Maybe<UiPageConfigType>>>;
  portal?: Maybe<Scalars["String"]>;
};

export type UiPageConfigType = {
  __typename?: "UIPageConfigType";
  components?: Maybe<Array<Maybe<UiComponentConfigType>>>;
  pageName?: Maybe<Scalars["String"]>;
};

export type UiPortalConfigType = {
  __typename?: "UIPortalConfigType";
  config?: Maybe<UiConfigRecordType>;
  uiConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type UserType = {
  __typename?: "UserType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type ValidationResult = {
  __typename?: "ValidationResult";
  errorMessage?: Maybe<Scalars["String"]>;
  isValidated?: Maybe<Scalars["Boolean"]>;
};

export type ViewFilters = {
  __typename?: "ViewFilters";
  applicationType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  caseState?: Maybe<Array<Maybe<Scalars["String"]>>>;
  englishEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  generalEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathway?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwayEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  school?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type ViewFiltersInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathway?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type Views = {
  __typename?: "Views";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  viewFilters?: Maybe<ViewFilters>;
  viewName?: Maybe<Scalars["String"]>;
};

export type ViewsInput = {
  _id?: InputMaybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewFilters?: InputMaybe<ViewFiltersInput>;
  viewName?: InputMaybe<Scalars["String"]>;
};

export type CountryResponseType = {
  __typename?: "countryResponseType";
  _id?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  isoCountryCode?: Maybe<Scalars["String"]>;
};

export type GlDistributionReportFilterInput = {
  transactionDateFrom?: InputMaybe<Scalars["String"]>;
  transactionDateTo?: InputMaybe<Scalars["String"]>;
};

export type GlDistributionReportResponseType = {
  __typename?: "glDistributionReportResponseType";
  amount?: Maybe<Scalars["Int"]>;
  applicationType?: Maybe<Scalars["String"]>;
  feeType?: Maybe<Scalars["String"]>;
  glCreditAccountId?: Maybe<Scalars["String"]>;
  glDebitAccountId?: Maybe<Scalars["String"]>;
  paymentNetworkReferenceId?: Maybe<Scalars["String"]>;
  transactionTimestamp?: Maybe<Scalars["DateString"]>;
  transactionType?: Maybe<Scalars["String"]>;
  trsAccountId?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type OscexamInput = {
  _id?: InputMaybe<Scalars["String"]>;
  examCode?: InputMaybe<Scalars["String"]>;
  examFullName?: InputMaybe<Scalars["String"]>;
  examShortTitle?: InputMaybe<Scalars["String"]>;
};

export type OscexamResponseType = {
  __typename?: "oscexamResponseType";
  _id?: Maybe<Scalars["String"]>;
  examCode?: Maybe<Scalars["String"]>;
  examFullName?: Maybe<Scalars["String"]>;
  examShortTitle?: Maybe<Scalars["String"]>;
};

export type PhysicianRegAuthorityInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
};

export type PhysicianRegAuthorityResponseType = {
  __typename?: "physicianRegAuthorityResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryCode?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
};

export type RegulatoryAuthorityInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
};

export type RegulatoryAuthorityResponseType = {
  __typename?: "regulatoryAuthorityResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryName?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
};

export type AuthorityAttestationDashboardGetAttestationRecordsQueryVariables =
  Exact<{
    attestationOutput?: InputMaybe<Scalars["String"]>;
    usmleId?: InputMaybe<Scalars["String"]>;
  }>;

export type AuthorityAttestationDashboardGetAttestationRecordsQuery = {
  __typename?: "Query";
  authorityPortal?:
    | {
        __typename?: "AuthorityPortalResponseType";
        attestationRecords?:
          | Array<
              | {
                  __typename?: "AttestationRecordType";
                  dataVersion?: number | null | undefined;
                  usmleId?: string | null | undefined;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  dateOfBirth?: string | null | undefined;
                  gradYear?: string | null | undefined;
                  gradSchool?: string | null | undefined;
                  gradSchoolCountry?: string | null | undefined;
                  attestationOutput?: string | null | undefined;
                  requestTimestamp?: any | null | undefined;
                  completeTimestamp?: any | null | undefined;
                  completedBy?: string | null | undefined;
                  rejectionReason?: string | null | undefined;
                  rejectionReasonText?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AuthorityAttestationLayoutAuthorityPortalQueryVariables = Exact<{
  attestationOutput?: InputMaybe<Scalars["String"]>;
  usmleId?: InputMaybe<Scalars["String"]>;
}>;

export type AuthorityAttestationLayoutAuthorityPortalQuery = {
  __typename?: "Query";
  authorityPortal?:
    | {
        __typename?: "AuthorityPortalResponseType";
        attestationRecords?:
          | Array<
              | {
                  __typename?: "AttestationRecordType";
                  dataVersion?: number | null | undefined;
                  usmleId?: string | null | undefined;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  dateOfBirth?: string | null | undefined;
                  gradYear?: string | null | undefined;
                  gradSchool?: string | null | undefined;
                  gradSchoolCountry?: string | null | undefined;
                  attestationOutput?: string | null | undefined;
                  requestTimestamp?: any | null | undefined;
                  completeTimestamp?: any | null | undefined;
                  completedBy?: string | null | undefined;
                  rejectionReason?: string | null | undefined;
                  rejectionReasonText?: string | null | undefined;
                  uiConfig?:
                    | {
                        __typename?: "UIPortalConfigType";
                        config?:
                          | {
                              __typename?: "UIConfigRecordType";
                              portal?: string | null | undefined;
                              pages?:
                                | Array<
                                    | {
                                        __typename?: "UIPageConfigType";
                                        pageName?: string | null | undefined;
                                        components?:
                                          | Array<
                                              | {
                                                  __typename?: "UIComponentConfigType";
                                                  componentName?:
                                                    | string
                                                    | null
                                                    | undefined;
                                                  version?:
                                                    | number
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AuthorityAttestationLayoutRejectMutationVariables = Exact<{
  usmleId: Scalars["String"];
  dataVersion: Scalars["Int"];
  rejectionReason: Scalars["String"];
  rejectionReasonText?: InputMaybe<Scalars["String"]>;
}>;

export type AuthorityAttestationLayoutRejectMutation = {
  __typename?: "Mutation";
  AuthorityPortal_reject?: string | null | undefined;
};

export type AuthorityAttestationLayoutAttestMutationVariables = Exact<{
  usmleId: Scalars["String"];
  dataVersion: Scalars["Int"];
}>;

export type AuthorityAttestationLayoutAttestMutation = {
  __typename?: "Mutation";
  AuthorityPortal_attest?: string | null | undefined;
};

export type AuthorityMaintenanceServerDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AuthorityMaintenanceServerDateQuery = {
  __typename?: "Query";
  serverDate: any;
};

export type PhysicianApplicantDetailsDesktopApplicantDetailsFieldsFragment = {
  __typename?: "MiniCEXResponseType";
  summary?:
    | {
        __typename?: "MiniCEXSummaryType";
        applicantLastName?: string | null | undefined;
        applicantRestOfName?: string | null | undefined;
        usmleId?: string | null | undefined;
        encounterId?: string | null | undefined;
        createdAt?: any | null | undefined;
        acceptedAt?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianAttestationDesktopPhysicianFieldsFragment = {
  __typename?: "PhysicianPortalResponseType";
  physician?:
    | {
        __typename?: "PhysicianResponseType";
        profile?:
          | {
              __typename?: "PhysicianProfileType";
              restOfName?: string | null | undefined;
              lastName?: string | null | undefined;
              dateOfBirth?: any | null | undefined;
              licenses?:
                | Array<
                    | {
                        __typename?: "PhysicianLicenseRecordType";
                        orgName?: string | null | undefined;
                        countryName?: string | null | undefined;
                        licenseNumber?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianAttestationDesktopAttestationFieldsFragment = {
  __typename?: "MiniCEXFormType";
  attestation?:
    | {
        __typename?: "MiniCEXAttestationType";
        attestedAt?: Array<any | null | undefined> | null | undefined;
        hasApplicantEnrolledInClinicalRotation?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianAttestationDesktopAttestMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  input?: InputMaybe<AttestMiniCexInput>;
}>;

export type PhysicianAttestationDesktopAttestMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_attest?:
    | {
        __typename?: "MiniCEXResponseType";
        summary?:
          | {
              __typename?: "MiniCEXSummaryType";
              acceptedAt?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianAttestationDesktopRejectMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  rejectionReason: Scalars["String"];
  rejectionReasonComments?: InputMaybe<Scalars["String"]>;
}>;

export type PhysicianAttestationDesktopRejectMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_reject?:
    | {
        __typename?: "MiniCEXResponseType";
        summary?:
          | {
              __typename?: "MiniCEXSummaryType";
              rejectionReason?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianEncounterInformationEncounterFieldsFragment = {
  __typename?: "MiniCEXFormType";
  encounter?:
    | {
        __typename?: "MiniCEXEncounterType";
        encounterDate?: string | null | undefined;
        encounterSetting?: string | null | undefined;
        facility?:
          | {
              __typename?: "MiniCEXFacilityType";
              name?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              country?:
                | {
                    __typename?: "MiniCEXCountryType";
                    name?: string | null | undefined;
                    ref?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        patient?:
          | {
              __typename?: "MiniCEXPatientType";
              age?: number | null | undefined;
              ageYear?: number | null | undefined;
              ageMonth?: string | null | undefined;
              gender?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianEncounterInformationCountryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianEncounterInformationCountryQuery = {
  __typename?: "Query";
  country?:
    | Array<
        | {
            __typename?: "countryResponseType";
            _id?: string | null | undefined;
            countryName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PhysicianEncounterInformationSetEncounterMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  isComplete: Scalars["Boolean"];
  encounterDate?: InputMaybe<Scalars["String"]>;
  encounterSetting?: InputMaybe<Scalars["String"]>;
  facilityName?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  countryRef?: InputMaybe<Scalars["String"]>;
  patientAgeYear?: InputMaybe<Scalars["Int"]>;
  patientAgeMonth?: InputMaybe<Scalars["String"]>;
  patientGender?: InputMaybe<Scalars["String"]>;
}>;

export type PhysicianEncounterInformationSetEncounterMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_setEncounter?:
    | {
        __typename?: "MiniCEXResponseType";
        summary?:
          | {
              __typename?: "MiniCEXSummaryType";
              rejectionReason?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragment = {
  __typename?: "MiniCEXFormType";
  evaluation?:
    | {
        __typename?: "MiniCEXEvaluationType";
        additionalInfoText?: string | null | undefined;
        categories?:
          | Array<
              | {
                  __typename?: "MiniCEXCategoryType";
                  name?: string | null | undefined;
                  reasonText?: string | null | undefined;
                  isAdditionalInfoRequired?: boolean | null | undefined;
                  score?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexAdditionalDetailsSetAdditionalInfoMutationVariables =
  Exact<{
    encounterId: Scalars["String"];
    dataVersion: Scalars["Int"];
    isComplete: Scalars["Boolean"];
    input: MiniCexAdditionalInfoInput;
  }>;

export type PhysicianMiniCexAdditionalDetailsSetAdditionalInfoMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_setAdditionalInfo?:
    | {
        __typename?: "MiniCEXResponseType";
        dataVersion?: number | null | undefined;
        form?:
          | {
              __typename?: "MiniCEXFormType";
              evaluation?:
                | {
                    __typename?: "MiniCEXEvaluationType";
                    categories?:
                      | Array<
                          | {
                              __typename?: "MiniCEXCategoryType";
                              isAdditionalInfoRequired?:
                                | boolean
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexEvaluationDetailsFieldsFragment = {
  __typename?: "MiniCEXFormType";
  evaluation?:
    | {
        __typename?: "MiniCEXEvaluationType";
        additionalInfoText?: string | null | undefined;
        categories?:
          | Array<
              | {
                  __typename?: "MiniCEXCategoryType";
                  name?: string | null | undefined;
                  reasonText?: string | null | undefined;
                  isAdditionalInfoRequired?: boolean | null | undefined;
                  score?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexEvaluationSetEvaluationMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  isComplete: Scalars["Boolean"];
  input: MiniCexEvaluationInput;
}>;

export type PhysicianMiniCexEvaluationSetEvaluationMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_setEvaluation?:
    | {
        __typename?: "MiniCEXResponseType";
        dataVersion?: number | null | undefined;
        form?:
          | {
              __typename?: "MiniCEXFormType";
              evaluation?:
                | {
                    __typename?: "MiniCEXEvaluationType";
                    categories?:
                      | Array<
                          | {
                              __typename?: "MiniCEXCategoryType";
                              isAdditionalInfoRequired?:
                                | boolean
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              progress?:
                | Array<
                    | {
                        __typename?: "MiniCEXFormProgressType";
                        state?: string | null | undefined;
                        status?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexReviewPhysicianPortalQueryVariables = Exact<{
  encounterId: Scalars["String"];
}>;

export type PhysicianMiniCexReviewPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        minicex?:
          | {
              __typename?: "MiniCEXResponseType";
              dataVersion?: number | null | undefined;
              uiConfig?:
                | {
                    __typename?: "UIPortalConfigType";
                    config?:
                      | {
                          __typename?: "UIConfigRecordType";
                          pages?:
                            | Array<
                                | {
                                    __typename?: "UIPageConfigType";
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: "UIComponentConfigType";
                                              componentName?:
                                                | string
                                                | null
                                                | undefined;
                                              version?:
                                                | number
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              summary?:
                | {
                    __typename?: "MiniCEXSummaryType";
                    applicantLastName?: string | null | undefined;
                    applicantRestOfName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    encounterId?: string | null | undefined;
                    createdAt?: any | null | undefined;
                    acceptedAt?: any | null | undefined;
                    completedAt?: any | null | undefined;
                    physicianLastNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                    physicianRestOfNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              form?:
                | {
                    __typename?: "MiniCEXFormType";
                    encounter?:
                      | {
                          __typename?: "MiniCEXEncounterType";
                          encounterDate?: string | null | undefined;
                          encounterSetting?: string | null | undefined;
                          facility?:
                            | {
                                __typename?: "MiniCEXFacilityType";
                                name?: string | null | undefined;
                                city?: string | null | undefined;
                                state?: string | null | undefined;
                                country?:
                                  | {
                                      __typename?: "MiniCEXCountryType";
                                      ref?: string | null | undefined;
                                      name?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          patient?:
                            | {
                                __typename?: "MiniCEXPatientType";
                                age?: number | null | undefined;
                                ageYear?: number | null | undefined;
                                ageMonth?: string | null | undefined;
                                gender?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    evaluation?:
                      | {
                          __typename?: "MiniCEXEvaluationType";
                          additionalInfoText?: string | null | undefined;
                          categories?:
                            | Array<
                                | {
                                    __typename?: "MiniCEXCategoryType";
                                    name?: string | null | undefined;
                                    score?: number | null | undefined;
                                    reasonText?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexReviewCompleteMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  preSubmissionSurvey?: InputMaybe<PhysicianMiniCexPreSubmissionSurveyInput>;
}>;

export type PhysicianMiniCexReviewCompleteMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_complete?:
    | {
        __typename?: "MiniCEXResponseType";
        dataVersion?: number | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianEvaluationLayoutPhysicianPortalQueryVariables = Exact<{
  encounterId: Scalars["String"];
}>;

export type PhysicianEvaluationLayoutPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              email?: string | null | undefined;
              minicex?:
                | {
                    __typename?: "PhysicianMinicexAccountType";
                    profileStatus?: string | null | undefined;
                    faimerSurveyCurrentPathwaySeason?:
                      | {
                          __typename?: "PhysicianFaimerSurveyType";
                          completedAt?: any | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              profile?:
                | {
                    __typename?: "PhysicianProfileType";
                    restOfName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    dateOfBirth?: any | null | undefined;
                    licenses?:
                      | Array<
                          | {
                              __typename?: "PhysicianLicenseRecordType";
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                              licenseNumber?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        minicex?:
          | {
              __typename?: "MiniCEXResponseType";
              minicexId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              uiConfig?:
                | {
                    __typename?: "UIPortalConfigType";
                    config?:
                      | {
                          __typename?: "UIConfigRecordType";
                          pages?:
                            | Array<
                                | {
                                    __typename?: "UIPageConfigType";
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: "UIComponentConfigType";
                                              componentName?:
                                                | string
                                                | null
                                                | undefined;
                                              version?:
                                                | number
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              form?:
                | {
                    __typename?: "MiniCEXFormType";
                    progress?:
                      | Array<
                          | {
                              __typename?: "MiniCEXFormProgressType";
                              state?: string | null | undefined;
                              status?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    attestation?:
                      | {
                          __typename?: "MiniCEXAttestationType";
                          attestedAt?:
                            | Array<any | null | undefined>
                            | null
                            | undefined;
                          hasApplicantEnrolledInClinicalRotation?:
                            | string
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    encounter?:
                      | {
                          __typename?: "MiniCEXEncounterType";
                          encounterDate?: string | null | undefined;
                          encounterSetting?: string | null | undefined;
                          facility?:
                            | {
                                __typename?: "MiniCEXFacilityType";
                                name?: string | null | undefined;
                                city?: string | null | undefined;
                                state?: string | null | undefined;
                                country?:
                                  | {
                                      __typename?: "MiniCEXCountryType";
                                      name?: string | null | undefined;
                                      ref?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          patient?:
                            | {
                                __typename?: "MiniCEXPatientType";
                                age?: number | null | undefined;
                                ageYear?: number | null | undefined;
                                ageMonth?: string | null | undefined;
                                gender?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    evaluation?:
                      | {
                          __typename?: "MiniCEXEvaluationType";
                          additionalInfoText?: string | null | undefined;
                          categories?:
                            | Array<
                                | {
                                    __typename?: "MiniCEXCategoryType";
                                    name?: string | null | undefined;
                                    reasonText?: string | null | undefined;
                                    isAdditionalInfoRequired?:
                                      | boolean
                                      | null
                                      | undefined;
                                    score?: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              summary?:
                | {
                    __typename?: "MiniCEXSummaryType";
                    applicantLastName?: string | null | undefined;
                    applicantRestOfName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    encounterId?: string | null | undefined;
                    createdAt?: any | null | undefined;
                    acceptedAt?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDashboardClosedCardPhysicianPortalQueryVariables = Exact<{
  encounterId: Scalars["String"];
}>;

export type PhysicianDashboardClosedCardPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        minicex?:
          | {
              __typename?: "MiniCEXResponseType";
              summary?:
                | {
                    __typename?: "MiniCEXSummaryType";
                    applicantLastName?: string | null | undefined;
                    applicantRestOfName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    encounterId?: string | null | undefined;
                    createdAt?: any | null | undefined;
                    completedAt?: any | null | undefined;
                    acceptedAt?: any | null | undefined;
                    physicianLastNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                    physicianRestOfNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                    rejectionReason?: string | null | undefined;
                    rejectionReasonComments?: string | null | undefined;
                  }
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: "UIPortalConfigType";
                    config?:
                      | {
                          __typename?: "UIConfigRecordType";
                          pages?:
                            | Array<
                                | {
                                    __typename?: "UIPageConfigType";
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: "UIComponentConfigType";
                                              componentName?:
                                                | string
                                                | null
                                                | undefined;
                                              version?:
                                                | number
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              form?:
                | {
                    __typename?: "MiniCEXFormType";
                    encounter?:
                      | {
                          __typename?: "MiniCEXEncounterType";
                          encounterDate?: string | null | undefined;
                          encounterSetting?: string | null | undefined;
                          facility?:
                            | {
                                __typename?: "MiniCEXFacilityType";
                                name?: string | null | undefined;
                                city?: string | null | undefined;
                                state?: string | null | undefined;
                                country?:
                                  | {
                                      __typename?: "MiniCEXCountryType";
                                      ref?: string | null | undefined;
                                      name?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          patient?:
                            | {
                                __typename?: "MiniCEXPatientType";
                                age?: number | null | undefined;
                                ageYear?: number | null | undefined;
                                ageMonth?: string | null | undefined;
                                gender?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    evaluation?:
                      | {
                          __typename?: "MiniCEXEvaluationType";
                          additionalInfoText?: string | null | undefined;
                          categories?:
                            | Array<
                                | {
                                    __typename?: "MiniCEXCategoryType";
                                    name?: string | null | undefined;
                                    score?: number | null | undefined;
                                    reasonText?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDashboardClosedTablePhysicianPortalQueryVariables = Exact<{
  encounterId: Scalars["String"];
}>;

export type PhysicianDashboardClosedTablePhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        minicex?:
          | {
              __typename?: "MiniCEXResponseType";
              summary?:
                | {
                    __typename?: "MiniCEXSummaryType";
                    applicantLastName?: string | null | undefined;
                    applicantRestOfName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    encounterId?: string | null | undefined;
                    createdAt?: any | null | undefined;
                    acceptedAt?: any | null | undefined;
                    completedAt?: any | null | undefined;
                    physicianLastNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                    physicianRestOfNameProvidedByPhysician?:
                      | string
                      | null
                      | undefined;
                    rejectionReason?: string | null | undefined;
                    rejectionReasonComments?: string | null | undefined;
                  }
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: "UIPortalConfigType";
                    config?:
                      | {
                          __typename?: "UIConfigRecordType";
                          pages?:
                            | Array<
                                | {
                                    __typename?: "UIPageConfigType";
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: "UIComponentConfigType";
                                              componentName?:
                                                | string
                                                | null
                                                | undefined;
                                              version?:
                                                | number
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              form?:
                | {
                    __typename?: "MiniCEXFormType";
                    encounter?:
                      | {
                          __typename?: "MiniCEXEncounterType";
                          encounterDate?: string | null | undefined;
                          encounterSetting?: string | null | undefined;
                          facility?:
                            | {
                                __typename?: "MiniCEXFacilityType";
                                name?: string | null | undefined;
                                city?: string | null | undefined;
                                state?: string | null | undefined;
                                country?:
                                  | {
                                      __typename?: "MiniCEXCountryType";
                                      ref?: string | null | undefined;
                                      name?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          patient?:
                            | {
                                __typename?: "MiniCEXPatientType";
                                age?: number | null | undefined;
                                ageYear?: number | null | undefined;
                                ageMonth?: string | null | undefined;
                                gender?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    evaluation?:
                      | {
                          __typename?: "MiniCEXEvaluationType";
                          additionalInfoText?: string | null | undefined;
                          categories?:
                            | Array<
                                | {
                                    __typename?: "MiniCEXCategoryType";
                                    name?: string | null | undefined;
                                    score?: number | null | undefined;
                                    reasonText?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDashboardLayoutPhysicianPortalQueryVariables = Exact<{
  responseType: Scalars["String"];
}>;

export type PhysicianDashboardLayoutPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              physicianId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              email?: string | null | undefined;
              applicantsEvaluatedByPhysician?:
                | Array<
                    | {
                        __typename?: "A_Applicant";
                        usmleId?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              physicianConfig?:
                | {
                    __typename?: "PhysicianConfigType";
                    config?:
                      | {
                          __typename?: "PhysicianConfigRecordType";
                          minicex?:
                            | {
                                __typename?: "PhysicianMiniCEXConfigType";
                                maxApplicantsAllowedToBeEvaluatedByPhysician?:
                                  | number
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              profile?:
                | {
                    __typename?: "PhysicianProfileType";
                    dateOfBirth?: any | null | undefined;
                    restOfName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    licenses?:
                      | Array<
                          | {
                              __typename?: "PhysicianLicenseRecordType";
                              physicianRegAuthorityRef?:
                                | number
                                | null
                                | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                              licenseNumber?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              minicex?:
                | {
                    __typename?: "PhysicianMinicexAccountType";
                    accountStatus?: string | null | undefined;
                    profileStatus?: string | null | undefined;
                    tags?:
                      | {
                          __typename?: "PhysicianTagsType";
                          actionTags?:
                            | Array<string | null | undefined>
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        minicexList?:
          | Array<
              | {
                  __typename?: "MiniCEXSummaryType";
                  minicexId?: string | null | undefined;
                  dataVersion?: number | null | undefined;
                  encounterId?: string | null | undefined;
                  physicianEmail?: string | null | undefined;
                  physicianLastNameProvidedByApplicant?:
                    | string
                    | null
                    | undefined;
                  physicianRestOfNameProvidedByApplicant?:
                    | string
                    | null
                    | undefined;
                  physicianLastNameProvidedByPhysician?:
                    | string
                    | null
                    | undefined;
                  physicianRestOfNameProvidedByPhysician?:
                    | string
                    | null
                    | undefined;
                  usmleId?: string | null | undefined;
                  applicantLastName?: string | null | undefined;
                  applicantRestOfName?: string | null | undefined;
                  acceptedAt?: any | null | undefined;
                  createdAt?: any | null | undefined;
                  expiresAt?: any | null | undefined;
                  completedAt?: any | null | undefined;
                  state?: string | null | undefined;
                  allowedActions?:
                    | Array<string | null | undefined>
                    | null
                    | undefined;
                  isActive?: boolean | null | undefined;
                  rejectionReason?: string | null | undefined;
                  rejectionReasonComments?: string | null | undefined;
                  stateModel?:
                    | Array<
                        | {
                            __typename?: "MiniCEXStateModelType";
                            state?: string | null | undefined;
                            timestamp?: any | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDashboardLayoutMiniCexAcceptMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
}>;

export type PhysicianDashboardLayoutMiniCexAcceptMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_accept?:
    | {
        __typename?: "MiniCEXResponseType";
        dataVersion?: number | null | undefined;
        summary?:
          | {
              __typename?: "MiniCEXSummaryType";
              minicexId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              encounterId?: string | null | undefined;
              physicianEmail?: string | null | undefined;
              physicianLastNameProvidedByApplicant?: string | null | undefined;
              physicianRestOfNameProvidedByApplicant?:
                | string
                | null
                | undefined;
              physicianLastNameProvidedByPhysician?: string | null | undefined;
              physicianRestOfNameProvidedByPhysician?:
                | string
                | null
                | undefined;
              usmleId?: string | null | undefined;
              applicantLastName?: string | null | undefined;
              applicantRestOfName?: string | null | undefined;
              createdAt?: any | null | undefined;
              expiresAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              state?: string | null | undefined;
              allowedActions?:
                | Array<string | null | undefined>
                | null
                | undefined;
              isActive?: boolean | null | undefined;
              stateModel?:
                | Array<
                    | {
                        __typename?: "MiniCEXStateModelType";
                        state?: string | null | undefined;
                        timestamp?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDashboardLayoutMiniCexRejectMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  input: RejectMiniCexInput;
}>;

export type PhysicianDashboardLayoutMiniCexRejectMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_reject?:
    | {
        __typename?: "MiniCEXResponseType";
        dataVersion?: number | null | undefined;
        summary?:
          | {
              __typename?: "MiniCEXSummaryType";
              minicexId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              encounterId?: string | null | undefined;
              physicianEmail?: string | null | undefined;
              physicianLastNameProvidedByApplicant?: string | null | undefined;
              physicianRestOfNameProvidedByApplicant?:
                | string
                | null
                | undefined;
              physicianLastNameProvidedByPhysician?: string | null | undefined;
              physicianRestOfNameProvidedByPhysician?:
                | string
                | null
                | undefined;
              usmleId?: string | null | undefined;
              applicantLastName?: string | null | undefined;
              applicantRestOfName?: string | null | undefined;
              createdAt?: any | null | undefined;
              expiresAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              state?: string | null | undefined;
              allowedActions?:
                | Array<string | null | undefined>
                | null
                | undefined;
              isActive?: boolean | null | undefined;
              stateModel?:
                | Array<
                    | {
                        __typename?: "MiniCEXStateModelType";
                        state?: string | null | undefined;
                        timestamp?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianGatewayPhysicianPortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianGatewayPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              minicex?:
                | {
                    __typename?: "PhysicianMinicexAccountType";
                    accountStatus?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianLayoutPhysicianPortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianLayoutPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              minicex?:
                | {
                    __typename?: "PhysicianMinicexAccountType";
                    accountStatus?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianMiniCexSurveySetSurveyMutationVariables = Exact<{
  encounterId: Scalars["String"];
  dataVersion: Scalars["Int"];
  input?: InputMaybe<MiniCexSurveyInput>;
}>;

export type PhysicianMiniCexSurveySetSurveyMutation = {
  __typename?: "Mutation";
  PhysicianPortal_MiniCEX_setSurvey?:
    | {
        __typename?: "ResponseStatusType";
        msg?: string | null | undefined;
        code?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianOptOutNoAuthOptOutNoAuthMutationVariables = Exact<{
  token: Scalars["String"];
  email: Scalars["String"];
  reason?: InputMaybe<Scalars["String"]>;
  reasonComments?: InputMaybe<Scalars["String"]>;
}>;

export type PhysicianOptOutNoAuthOptOutNoAuthMutation = {
  __typename?: "Mutation";
  PhysicianPortal_Mgmt_optOut_noauth?:
    | {
        __typename?: "ResponseStatusType";
        code?: string | null | undefined;
        msg?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianOptOutDataVersionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianOptOutDataVersionQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              dataVersion?: number | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianOptOutOptOutMutationVariables = Exact<{
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  reason?: InputMaybe<Scalars["String"]>;
  reasonComments?: InputMaybe<Scalars["String"]>;
}>;

export type PhysicianOptOutOptOutMutation = {
  __typename?: "Mutation";
  PhysicianPortal_Mgmt_optOut?:
    | {
        __typename?: "PhysicianResponseType";
        dataVersion?: number | null | undefined;
      }
    | null
    | undefined;
};

export type PhysicianProfileManagementCountryListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianProfileManagementCountryListQuery = {
  __typename?: "Query";
  country?:
    | Array<
        | {
            __typename?: "countryResponseType";
            _id?: string | null | undefined;
            countryName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PhysicianProfileManagementPhysicianRegAuthorityQueryVariables =
  Exact<{
    input?: InputMaybe<PhysicianRegAuthorityInput>;
  }>;

export type PhysicianProfileManagementPhysicianRegAuthorityQuery = {
  __typename?: "Query";
  physicianRegAuthority?:
    | Array<
        | {
            __typename?: "physicianRegAuthorityResponseType";
            _id?: number | null | undefined;
            orgName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PhysicianProfileManagementPhysicianPortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PhysicianProfileManagementPhysicianPortalQuery = {
  __typename?: "Query";
  physicianPortal?:
    | {
        __typename?: "PhysicianPortalResponseType";
        physician?:
          | {
              __typename?: "PhysicianResponseType";
              physicianId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              email?: string | null | undefined;
              profile?:
                | {
                    __typename?: "PhysicianProfileType";
                    restOfName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    medicalSpecialty?: string | null | undefined;
                    dateOfBirth?: any | null | undefined;
                    usmleId?: string | null | undefined;
                    isHoldingFacultyAppointment?: string | null | undefined;
                    secondaryEmails?:
                      | Array<string | null | undefined>
                      | null
                      | undefined;
                    licenses?:
                      | Array<
                          | {
                              __typename?: "PhysicianLicenseRecordType";
                              _id?: string | null | undefined;
                              physicianRegAuthorityRef?:
                                | number
                                | null
                                | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                              licenseNumber?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              minicex?:
                | {
                    __typename?: "PhysicianMinicexAccountType";
                    accountStatus?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianProfileManagementSetProfileMutationVariables = Exact<{
  dataVersion: Scalars["Int"];
  input: PhysicianProfileInput;
}>;

export type PhysicianProfileManagementSetProfileMutation = {
  __typename?: "Mutation";
  PhysicianPortal_Mgmt_setProfile?:
    | {
        __typename?: "PhysicianResponseType";
        physicianId?: string | null | undefined;
        dataVersion?: number | null | undefined;
        email?: string | null | undefined;
        profile?:
          | {
              __typename?: "PhysicianProfileType";
              restOfName?: string | null | undefined;
              lastName?: string | null | undefined;
              medicalSpecialty?: string | null | undefined;
              dateOfBirth?: any | null | undefined;
              usmleId?: string | null | undefined;
              isHoldingFacultyAppointment?: string | null | undefined;
              secondaryEmails?:
                | Array<string | null | undefined>
                | null
                | undefined;
              licenses?:
                | Array<
                    | {
                        __typename?: "PhysicianLicenseRecordType";
                        _id?: string | null | undefined;
                        physicianRegAuthorityRef?: number | null | undefined;
                        orgName?: string | null | undefined;
                        countryName?: string | null | undefined;
                        licenseNumber?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        minicex?:
          | {
              __typename?: "PhysicianMinicexAccountType";
              accountStatus?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const PhysicianApplicantDetailsDesktopApplicantDetailsFieldsFragmentDoc =
  {
    kind: "Document",
    definitions: [
      {
        kind: "FragmentDefinition",
        name: {
          kind: "Name",
          value: "PhysicianApplicantDetailsDesktopApplicantDetailsFields",
        },
        typeCondition: {
          kind: "NamedType",
          name: { kind: "Name", value: "MiniCEXResponseType" },
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [
            {
              kind: "Field",
              name: { kind: "Name", value: "summary" },
              selectionSet: {
                kind: "SelectionSet",
                selections: [
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "applicantLastName" },
                  },
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "applicantRestOfName" },
                  },
                  { kind: "Field", name: { kind: "Name", value: "usmleId" } },
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "encounterId" },
                  },
                  { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "acceptedAt" },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    PhysicianApplicantDetailsDesktopApplicantDetailsFieldsFragment,
    unknown
  >;
export const PhysicianAttestationDesktopPhysicianFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PhysicianAttestationDesktopPhysicianFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PhysicianPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physician" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "restOfName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "licenses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orgName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseNumber" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianAttestationDesktopPhysicianFieldsFragment,
  unknown
>;
export const PhysicianAttestationDesktopAttestationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PhysicianAttestationDesktopAttestationFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MiniCEXFormType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attestation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "attestedAt" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "hasApplicantEnrolledInClinicalRotation",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianAttestationDesktopAttestationFieldsFragment,
  unknown
>;
export const PhysicianEncounterInformationEncounterFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PhysicianEncounterInformationEncounterFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MiniCEXFormType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "encounter" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "encounterDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "encounterSetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "facility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ref" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "patient" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "age" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ageYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ageMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gender" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianEncounterInformationEncounterFieldsFragment,
  unknown
>;
export const PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PhysicianMiniCEXAdditionalDetailsEvaluationFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MiniCEXFormType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "evaluation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reasonText" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isAdditionalInfoRequired",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "additionalInfoText" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragment,
  unknown
>;
export const PhysicianMiniCexEvaluationDetailsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PhysicianMiniCEXEvaluationDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MiniCEXFormType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "evaluation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reasonText" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isAdditionalInfoRequired",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "additionalInfoText" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexEvaluationDetailsFieldsFragment,
  unknown
>;
export const AuthorityAttestationDashboardGetAttestationRecordsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "AuthorityAttestationDashboardGetAttestationRecords",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attestationOutput" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usmleId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authorityPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attestationRecords" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "attestationOutput" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "attestationOutput",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "usmleId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "usmleId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradSchool" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradSchoolCountry" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attestationOutput" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestTimestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completeTimestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReasonText" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthorityAttestationDashboardGetAttestationRecordsQuery,
  AuthorityAttestationDashboardGetAttestationRecordsQueryVariables
>;
export const AuthorityAttestationLayoutAuthorityPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "AuthorityAttestationLayoutAuthorityPortal",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attestationOutput" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usmleId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authorityPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attestationRecords" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "attestationOutput" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "attestationOutput",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "usmleId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "usmleId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradSchool" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gradSchoolCountry" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attestationOutput" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestTimestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completeTimestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReasonText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uiConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "portal" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "components",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "componentName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "version",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pageName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthorityAttestationLayoutAuthorityPortalQuery,
  AuthorityAttestationLayoutAuthorityPortalQueryVariables
>;
export const AuthorityAttestationLayoutRejectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AuthorityAttestationLayoutReject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usmleId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rejectionReason" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rejectionReasonText" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AuthorityPortal_reject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "usmleId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "usmleId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rejectionReason" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rejectionReason" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rejectionReasonText" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rejectionReasonText" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthorityAttestationLayoutRejectMutation,
  AuthorityAttestationLayoutRejectMutationVariables
>;
export const AuthorityAttestationLayoutAttestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AuthorityAttestationLayoutAttest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usmleId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AuthorityPortal_attest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "usmleId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "usmleId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthorityAttestationLayoutAttestMutation,
  AuthorityAttestationLayoutAttestMutationVariables
>;
export const AuthorityMaintenanceServerDateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthorityMaintenanceServerDate" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "serverDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthorityMaintenanceServerDateQuery,
  AuthorityMaintenanceServerDateQueryVariables
>;
export const PhysicianAttestationDesktopAttestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianAttestationDesktopAttest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AttestMiniCEXInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_attest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianAttestationDesktopAttestMutation,
  PhysicianAttestationDesktopAttestMutationVariables
>;
export const PhysicianAttestationDesktopRejectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianAttestationDesktopReject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rejectionReason" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rejectionReasonComments" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_reject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "rejectionReason" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "rejectionReasonComments" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "rejectionReasonComments",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianAttestationDesktopRejectMutation,
  PhysicianAttestationDesktopRejectMutationVariables
>;
export const PhysicianEncounterInformationCountryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianEncounterInformationCountry" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianEncounterInformationCountryQuery,
  PhysicianEncounterInformationCountryQueryVariables
>;
export const PhysicianEncounterInformationSetEncounterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "PhysicianEncounterInformationSetEncounter",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterSetting" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "facilityName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "state" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryRef" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientAgeYear" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientAgeMonth" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "patientGender" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "PhysicianPortal_MiniCEX_setEncounter",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isComplete" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "encounterDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "encounterSetting" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterSetting" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "facility" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "name" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "facilityName" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "city" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "city" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "state" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "state" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "country" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "name" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ref" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "countryRef" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "patient" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ageYear" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "patientAgeYear" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ageMonth" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "patientAgeMonth" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "gender" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "patientGender" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianEncounterInformationSetEncounterMutation,
  PhysicianEncounterInformationSetEncounterMutationVariables
>;
export const PhysicianMiniCexAdditionalDetailsSetAdditionalInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "PhysicianMiniCEXAdditionalDetailsSetAdditionalInfo",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiniCEXAdditionalInfoInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "PhysicianPortal_MiniCEX_setAdditionalInfo",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isComplete" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "form" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "evaluation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categories" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isAdditionalInfoRequired",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexAdditionalDetailsSetAdditionalInfoMutation,
  PhysicianMiniCexAdditionalDetailsSetAdditionalInfoMutationVariables
>;
export const PhysicianMiniCexEvaluationSetEvaluationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianMiniCEXEvaluationSetEvaluation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiniCEXEvaluationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "PhysicianPortal_MiniCEX_setEvaluation",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isComplete" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "form" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "evaluation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categories" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isAdditionalInfoRequired",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "progress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexEvaluationSetEvaluationMutation,
  PhysicianMiniCexEvaluationSetEvaluationMutationVariables
>;
export const PhysicianMiniCexReviewPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianMiniCEXReviewPhysicianPortal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicex" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "encounterId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uiConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pageName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "components",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "componentName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "version",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantLastName",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantRestOfName",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usmleId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounterId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acceptedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "completedAt" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianLastNameProvidedByPhysician",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianRestOfNameProvidedByPhysician",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "form" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterSetting",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "facility" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "city" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "state",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "country",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ref",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "patient" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "age" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageYear",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageMonth",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "gender",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "evaluation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categories" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "score",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "reasonText",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "additionalInfoText",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexReviewPhysicianPortalQuery,
  PhysicianMiniCexReviewPhysicianPortalQueryVariables
>;
export const PhysicianMiniCexReviewCompleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianMiniCEXReviewComplete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preSubmissionSurvey" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "PhysicianMiniCEXPreSubmissionSurveyInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_complete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "preSubmissionSurvey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "preSubmissionSurvey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexReviewCompleteMutation,
  PhysicianMiniCexReviewCompleteMutationVariables
>;
export const PhysicianEvaluationLayoutPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianEvaluationLayoutPhysicianPortal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicex" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profileStatus" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "faimerSurveyCurrentPathwaySeason",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "completedAt",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PhysicianAttestationDesktopPhysicianFields",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicex" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "encounterId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicexId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value:
                            "PhysicianApplicantDetailsDesktopApplicantDetailsFields",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uiConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pageName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "components",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "componentName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "version",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "form" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value:
                                  "PhysicianAttestationDesktopAttestationFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value:
                                  "PhysicianEncounterInformationEncounterFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value:
                                  "PhysicianMiniCEXEvaluationDetailsFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value:
                                  "PhysicianMiniCEXAdditionalDetailsEvaluationFields",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "progress" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PhysicianAttestationDesktopPhysicianFieldsFragmentDoc.definitions,
    ...PhysicianApplicantDetailsDesktopApplicantDetailsFieldsFragmentDoc.definitions,
    ...PhysicianAttestationDesktopAttestationFieldsFragmentDoc.definitions,
    ...PhysicianEncounterInformationEncounterFieldsFragmentDoc.definitions,
    ...PhysicianMiniCexEvaluationDetailsFieldsFragmentDoc.definitions,
    ...PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PhysicianEvaluationLayoutPhysicianPortalQuery,
  PhysicianEvaluationLayoutPhysicianPortalQueryVariables
>;
export const PhysicianDashboardClosedCardPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "PhysicianDashboardClosedCardPhysicianPortal",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicex" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "encounterId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantLastName",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantRestOfName",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usmleId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounterId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "completedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acceptedAt" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianLastNameProvidedByPhysician",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianRestOfNameProvidedByPhysician",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rejectionReason" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "rejectionReasonComments",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uiConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pageName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "components",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "componentName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "version",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "form" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterSetting",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "facility" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "city" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "state",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "country",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ref",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "patient" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "age" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageYear",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageMonth",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "gender",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "evaluation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categories" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "score",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "reasonText",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "additionalInfoText",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDashboardClosedCardPhysicianPortalQuery,
  PhysicianDashboardClosedCardPhysicianPortalQueryVariables
>;
export const PhysicianDashboardClosedTablePhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "PhysicianDashboardClosedTablePhysicianPortal",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicex" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "encounterId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "encounterId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantLastName",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantRestOfName",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usmleId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounterId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acceptedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "completedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acceptedAt" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianLastNameProvidedByPhysician",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianRestOfNameProvidedByPhysician",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rejectionReason" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "rejectionReasonComments",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uiConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "pageName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "components",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "componentName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "version",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "form" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encounter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "encounterSetting",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "facility" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "city" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "state",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "country",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ref",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "patient" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "age" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageYear",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ageMonth",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "gender",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "evaluation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categories" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "score",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "reasonText",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "additionalInfoText",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDashboardClosedTablePhysicianPortalQuery,
  PhysicianDashboardClosedTablePhysicianPortalQueryVariables
>;
export const PhysicianDashboardLayoutPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianDashboardLayoutPhysicianPortal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "responseType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "applicantsEvaluatedByPhysician",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usmleId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "minicex" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "maxApplicantsAllowedToBeEvaluatedByPhysician",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateOfBirth" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "restOfName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenses" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "physicianRegAuthorityRef",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "licenseNumber",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicex" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profileStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "actionTags" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicexList" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "responseType" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "responseType" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicexId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encounterId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianEmail" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantLastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantRestOfName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateModel" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timestamp" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedActions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rejectionReason" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rejectionReasonComments",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDashboardLayoutPhysicianPortalQuery,
  PhysicianDashboardLayoutPhysicianPortalQueryVariables
>;
export const PhysicianDashboardLayoutMiniCexAcceptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianDashboardLayoutMiniCEXAccept" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_accept" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicexId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encounterId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianEmail" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantLastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantRestOfName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateModel" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timestamp" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedActions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDashboardLayoutMiniCexAcceptMutation,
  PhysicianDashboardLayoutMiniCexAcceptMutationVariables
>;
export const PhysicianDashboardLayoutMiniCexRejectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianDashboardLayoutMiniCEXReject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RejectMiniCEXInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_reject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicexId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encounterId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianEmail" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantLastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicantRestOfName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateModel" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timestamp" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedActions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDashboardLayoutMiniCexRejectMutation,
  PhysicianDashboardLayoutMiniCexRejectMutationVariables
>;
export const PhysicianGatewayPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianGatewayPhysicianPortal" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicex" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountStatus" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianGatewayPhysicianPortalQuery,
  PhysicianGatewayPhysicianPortalQueryVariables
>;
export const PhysicianLayoutPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianLayoutPhysicianPortal" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicex" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountStatus" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianLayoutPhysicianPortalQuery,
  PhysicianLayoutPhysicianPortalQueryVariables
>;
export const PhysicianMiniCexSurveySetSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianMiniCEXSurveySetSurvey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MiniCEXSurveyInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_MiniCEX_setSurvey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "msg" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianMiniCexSurveySetSurveyMutation,
  PhysicianMiniCexSurveySetSurveyMutationVariables
>;
export const PhysicianOptOutNoAuthOptOutNoAuthDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianOptOutNoAuthOptOutNoAuth" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reason" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reasonComments" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_Mgmt_optOut_noauth" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "reason" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reason" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "reasonComments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reasonComments" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "msg" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianOptOutNoAuthOptOutNoAuthMutation,
  PhysicianOptOutNoAuthOptOutNoAuthMutationVariables
>;
export const PhysicianOptOutDataVersionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianOptOutDataVersion" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianOptOutDataVersionQuery,
  PhysicianOptOutDataVersionQueryVariables
>;
export const PhysicianOptOutOptOutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianOptOutOptOut" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reason" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reasonComments" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_Mgmt_optOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "reason" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reason" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "reasonComments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reasonComments" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianOptOutOptOutMutation,
  PhysicianOptOutOptOutMutationVariables
>;
export const PhysicianProfileManagementCountryListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PhysicianProfileManagementCountryList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianProfileManagementCountryListQuery,
  PhysicianProfileManagementCountryListQueryVariables
>;
export const PhysicianProfileManagementPhysicianRegAuthorityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "PhysicianProfileManagementPhysicianRegAuthority",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "physicianRegAuthorityInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianRegAuthority" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "orgName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianProfileManagementPhysicianRegAuthorityQuery,
  PhysicianProfileManagementPhysicianRegAuthorityQueryVariables
>;
export const PhysicianProfileManagementPhysicianPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "PhysicianProfileManagementPhysicianPortal",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "physicianPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physician" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "restOfName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medicalSpecialty" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateOfBirth" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "usmleId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isHoldingFacultyAppointment",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "secondaryEmails" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenses" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "physicianRegAuthorityRef",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "licenseNumber",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minicex" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountStatus" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianProfileManagementPhysicianPortalQuery,
  PhysicianProfileManagementPhysicianPortalQueryVariables
>;
export const PhysicianProfileManagementSetProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhysicianProfileManagementSetProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PhysicianProfileInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PhysicianPortal_Mgmt_setProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "physicianId" } },
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "restOfName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medicalSpecialty" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isHoldingFacultyAppointment",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "secondaryEmails" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "licenses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "physicianRegAuthorityRef",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orgName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseNumber" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicex" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accountStatus" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianProfileManagementSetProfileMutation,
  PhysicianProfileManagementSetProfileMutationVariables
>;
